import React from "react";
import { submitSignUp } from "../lib/submitNewsletterSignUp";

const FooterNewsletter = () => {
  return (
    <>
      <div className="bg-transparent">
        <div className="max-w-7xl mx-auto pr-4 py-6 sm:pr-6 lg:py-10 lg:pr-8">
          <h2 className="text-2xl font-bold tracking-tight text-cto_secondary_navy_blue max-w-md">
            <p>Want to be kept up to date?</p>
            <p className="text-cto_primary_orange">
              Sign up for the CTO Labs quarterly
            </p>
          </h2>
          <div className="mt-8 w-full max-w-md">
            <form className="flex-col" onSubmit={submitSignUp}>
              <label htmlFor="email-address" className="sr-only">
                Email address
              </label>
              <input
                id="emailAddress"
                name="emailAddress"
                type="email"
                autoComplete="email"
                required
                className="w-full flex border-1 border-solid border-rich-purple-300 text-rich-purple-900 px-5 py-3 placeholder-rich-purple-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-rich-purple-700 focus:ring-white rounded-full"
                placeholder="Enter your email"
              />
              <button
                type="submit"
                className="mt-4 w-full flex items-center justify-center px-5 py-3 border border-transparent shadow text-base font-medium rounded-full text-white bg-cto_primary_orange hover:bg-cto_primary_purple focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-700 focus:ring-white bg-invisible"
              >
                sign me up.
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default FooterNewsletter;
