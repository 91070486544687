import { absoluteUrl, mergeClasses } from "../../lib/_helper";
import Link from "next/link";

const ContainerButton = ({ blok }) => {
  return (
    <div>
      <div className="mt-6">
        <div className="mt-6">
          <Link
            href={absoluteUrl(blok?.buttonUrl?.story?.url ?? "#")}
            className={mergeClasses("inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-full shadow-sm",
              blok.buttonTextColour ? "text-" + blok.buttonTextColour : "text-white",
              blok.buttonBackgroundColour ? "bg-" + blok.buttonBackgroundColour : "bg-gray-400",
              blok.buttonBackgroundHoverColour ? "hover:bg-" + blok.buttonBackgroundHoverColour : "hover:bg-gray-500")}>

            {blok?.buttonText ?? "{buttonText}"}

          </Link>
        </div>
      </div>
    </div>
  );
}

export default ContainerButton;