import { sbEditable } from "@storyblok/storyblok-editable";
import { ResolveRichText } from "../../lib/storyblok";
import NextImage from "./../CustomImage";
import Link from "next/link";
import { absoluteUrl, mergeClasses } from "../../lib/_helper";
import "@animxyz/core";
import { XyzTransition } from "@animxyz/react";

const SimpleCenteredPanel = ({ blok }) => {
  const toggled = true;

  return (
    <div
      className={mergeClasses(
        blok.backgroundColour ? "bg-" + blok.backgroundColour : "bg-gray-400",
        "px-4 sm:px-6 lg:px-8"
      )}
      {...sbEditable(blok)}
    >
      <div
        className={mergeClasses(
          blok.leftAligned || "text-center",
          blok.verticlePadding ? "py-" + blok.verticlePadding : "",
          blok.horizontalPadding ? "px-" + blok.horizontalPadding : "",
          blok.extraWide ? "max-w-7xl" : "max-w-3xl",
          "mx-auto"
        )}
      >
        {blok?.h1Title?.length > 0 && (
          <XyzTransition
            appearVisible={{ threshold: 0, rootMargin: "-100px" }}
            xyz="fade left-5 duration-8 ease"
          >
            {toggled && (
              <h1
                className={mergeClasses(
                  blok.h1TitleWeight
                    ? "font-" + blok.h1TitleWeight
                    : "font-normal",
                  blok.h1TitleSize ? "text-" + blok.h1TitleSize : "text-6xl",
                  "py-6"
                )}
              >
                <span
                  className={mergeClasses(
                    blok.h1TitleColour
                      ? "text-" + blok.h1TitleColour
                      : "text-cto_secondary_navy_blue",
                    "block",
                    "font-montserrat"
                  )}
                >
                  {blok.h1Title}
                </span>
              </h1>
            )}
          </XyzTransition>
        )}
        {blok?.image?.filename?.length > 0 && (
          <div className="relative px-10 mx-auto max-w-3xl h-auto" style={{width: (blok.imageWidth || 768)+"px", height: (blok.imageHeight || 432)+"px"}}>
            <NextImage
              src={blok.image.filename}
              alt={blok.image.alt}
              width={blok.imageWidth || 768}
              height={blok.imageHeight || 432}
              className="object-contain"
            />
          </div>
        )}

        {blok?.h2Title?.length > 0 && (
          <h2
            className={mergeClasses(
              blok.h2TitleWeight ? "font-" + blok.h2TitleWeight : "font-normal",
              blok.h2TitleSize ? "text-" + blok.h2TitleSize : "text-6xl"
              // "sm:text-4xl"
            )}
          >
            <span
              className={mergeClasses(
                blok.h2TitleColour
                  ? "text-" + blok.h2TitleColour
                  : "text-cto_secondary_navy_blue",
                "block",
                "font-montserrat"
              )}
            >
              {blok.h2Title}
            </span>
          </h2>
        )}
        {blok?.h3Title?.length > 0 && (
          <h3
            className={mergeClasses(
              blok.h3TitleWeight ? "font-" + blok.h3TitleWeight : "font-normal",
              blok.h3TitleSize ? "text-" + blok.h3TitleSize : "text-4xl"
              // "sm:text-2xl"
            )}
          >
            <span
              className={mergeClasses(
                blok.h3TitleColour
                  ? "text-" + blok.h3TitleColour
                  : "text-cto_secondary_navy_blue",
                "block",
                "font-montserrat"
              )}
            >
              {blok.h3Title}
            </span>
          </h3>
        )}
        {blok?.includeBody > 0 && (
          <span
            className={mergeClasses(
              blok.bodyTextColour
                ? "text-" + blok.bodyTextColour
                : "text-cto_secondary_navy_blue",
              blok.bodyTextWeight
                ? "font-" + blok.bodyTextWeight
                : "font-medium",
              blok.bodyTextSize ? "text-" + blok.bodyTextSize : "text-base",
              "mt-4 leading-6",
              "font-dm-sans"
            )}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: ResolveRichText.render(blok.bodyText),
              }}
            />
          </span>
        )}
        {blok?.buttonText?.length > 0 && (
          (<Link
            href={absoluteUrl(blok.buttonUrl?.cached_url ?? "#")}
            className={mergeClasses(
              "my-4 w-full font-dm-sans hover:shadow hover:shadow-black inline-flex items-center justify-center border border-transparent rounded-full sm:w-auto",
              blok.buttonVerticlePadding
                ? "py-" + blok.buttonVerticlePadding
                : "py-3",
              blok.buttonTextWeight
                ? "font-" + blok.buttonTextWeight
                : "font-medium",
              blok.buttonTextSize 
                ? "text-" + blok.buttonTextSize 
                : "text-base",
              blok.buttonHorizontalPadding
                ? "px-" + blok.buttonHorizontalPadding
                : "p-6",
              blok.buttonTextColour
                ? "text-" + blok.buttonTextColour
                : "text-cto_secondary_light_warm_grey",
              blok.buttonTextHoverColour
                ? "hover:text-" + blok.buttonTextHoverColour
                : "hover:text-cto_secondary_navy_blue",
              blok.buttonColour
                ? "bg-" + blok.buttonColour
                : "bg-cto_secondary_navy_blue",
              blok.buttonHoverColour
                ? "hover:bg-" + blok.buttonHoverColour
                : "hover:bg-cto_secondary_light_warm_grey"
            )}>

            {blok.buttonText}

          </Link>)
        )}
      </div>
    </div>
  );
};

export default SimpleCenteredPanel;
