import Image from "next/legacy/image";
import Link from "next/link";
import { sbEditable } from "@storyblok/storyblok-editable";
import { absoluteUrl } from "../lib/_helper";
import { HeroIcon } from '../lib/HeroIcon';
import { mergeClasses, PropertyExists } from "../lib/_helper";

const HomeHeroCard = ({ blok, numCards }) => {
  return (
    <div {...sbEditable(blok)}>
      <div className="flex flex-col rounded-sm shadow-lg overflow-hidden h-full bg-cto_secondary_light_warm_grey">
        <div className="px-6 py-10 my-auto flex flex-col justify-between">
          {blok?.image?.filename?.length > 0 && (
            <div className={mergeClasses(
                "flex-shrink-0 py-6",
                numCards > 2 ? "mx-auto" : "px-12"
              )}>
              <div style={{ width: 200, height: 100, position: "relative" }}>
                <Image
                  className="h-48 w-full absolute left-0 object-cover"
                  src={blok?.image?.filename}
                  alt={blok?.image?.alt ?? ""}
                  layout="fill"
                  objectFit="contain"
                />
              </div>
            </div>
          )}
          <div className="px-12 py-6">
            <span className={mergeClasses("font-montserrat font-bold text-cto_secondary_navy_blue", "text-" + (PropertyExists(blok.titleFontSize) ? blok.titleFontSize : "4xl"))}>
              {blok?.title}
            </span>
          </div>
          {blok?.bodyText && 
            <div className="px-12">
              <span className="mt-3 text-base text-gray-500 font-dm-sans">
                {blok?.bodyText}
              </span>
            </div>
          }
          {blok?.urlText && <div className="p-12 mt-6 flex items-center">
            <Link href={absoluteUrl(blok?.url?.story?.full_slug)}>

              <span className="underline text-cto_primary_orange font-dm-sans text-lg font-bold bg-invisible">
                {blok?.urlText}
              </span>

            </Link>
          </div>}

        </div>
        {blok?.icon &&
            <div className="p-8 -mt-8">
              <span className="h-20 w-20 rounded-md flex items-center justify-center ml-auto mr-0">
                <HeroIcon icon={blok.icon} color='text-cto_secondary_navy_blue' size={20} outline />
              </span>
            </div>
          }
      </div>
    </div>
  );
};

export default HomeHeroCard;
