import { sbEditable } from "@storyblok/storyblok-editable";
import { absoluteUrl } from "../../lib/_helper";
import Link from "next/link";

export default function SimpleJustified({ blok }) {
  return (
    <div className="bg-indigo-50" {...sbEditable(blok)}>
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-24 lg:px-8 lg:flex lg:items-center lg:justify-between">
        <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 md:text-4xl">
          <span className="block">{blok.title}</span>
          <span className="block text-indigo-600">Start your free trial today.</span>
        </h2>
        <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
          <div className="inline-flex rounded-md shadow">
            <Link
              href={absoluteUrl(href="#")}
              className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700">
              
                Get started
              
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
