import { sbEditable } from "@storyblok/storyblok-editable";
import { ResolveRichText } from "../lib/storyblok";
import Link from "next/link";
import Image from "next/legacy/image";
import "@animxyz/core";
import { XyzTransition } from "@animxyz/react";
import Author from "./Author";
import { absoluteUrl, mergeClasses } from "../lib/_helper";

const PageHeader = ({ blok }) => {
  const toggled = true;

  return (
    <div {...sbEditable(blok)} className="relative font-dm-sans">
      <div className="absolute inset-0">
        {blok?.image?.filename?.length > 0 ? (
          <div className="bg-cto_secondary_navy_blue h-1/2 sm:h-1/2" />
        ) : (
          <div className="bg-cto_secondary_navy_blue h-4/6 sm:h-4/6" />
        )}
      </div>
      <div className="pt-24 max-w-5xl px-4 mx-auto sm:px-6 lg:px-8 relative">
        {blok?.category.length > 0 && (
          <XyzTransition
            appearVisible={{ threshold: 0, rootMargin: "-100px" }}
            xyz="fade right-5 duration-8 ease"
          >
            {toggled && (
              <span className="font-bold block text-white text-center">
                {blok.category}
              </span>
            )}
          </XyzTransition>
        )}

        {blok?.title?.length > 0 && (
          <XyzTransition
            appearVisible={{ threshold: 0, rootMargin: "-100px" }}
            xyz="fade left-5 duration-8 ease"
          >
            {toggled && (
              <h1 className="font-bold text-5xl pt-3 py-24 font-montserrat text-white text-center">
                <span className="">{blok.title}</span>
              </h1>
            )}
          </XyzTransition>
        )}

        {blok?.image?.filename?.length > 0 && (
          <div className="mx-auto border-8 border-white">
            <Image
              src={blok?.image?.filename}
              alt={blok?.image?.alt}
              className="min-w-min min-h-min object-cover"
              height={400}
              width={1200}
            />
          </div>
        )}

        <div
          className={mergeClasses(
            "pt-6 pb-12 max-w-3xl px-4 mx-auto sm:px-6 lg:px-8 relative",
            !blok.bottomBorder || "border-b border-gray-300"
          )}
        >

          <Author
            author={blok.author}
            publishedDate={blok.publishedDate}
            readingTime={blok.readingTime}
          />


          {blok?.hideBody == false && (
            <span className="text-cto_secondary_dark_grey font-normal text-lg leading-6 articleCopy"
              dangerouslySetInnerHTML={{
                __html: ResolveRichText.render(blok.bodyText),
              }}
            />
          )}

          {blok?.buttonText?.length > 0 && (
            (<Link
              href={absoluteUrl(blok?.buttonUrl?.story?.url ?? "#")}
              className="my-8 w-full inline-flex items-center justify-center border border-transparent rounded-full py-4 font-medium text-base px-10 text-cto_secondary_light_warm_grey bg-cto_primary_purple
            hover:shadow hover:shadow-black hover:bg-cto_secondary_navy_blue 
            sm:w-auto">

              {blok?.buttonText}

            </Link>)
          )}
        </div>
      </div>
    </div>
  );
};

export default PageHeader;
