import React from 'react'
import Image from "next/legacy/image";

export default function AbstractFloatingBackgroundShape({ blok }) {
  const marginTop = (((blok.yOffset ?? 0) * 10).toString() || "0") + "px"  
  const marginLeft = "-640px" // note: 640px is half of 'max-w-7xl' (1280px), so we are moving to the LHS of the content viewport
  const size = blok.size ?? 300;
  return (
    <div className="max-w-7xl mx-auto absolute left-1/2 w-full z-[-1]" style={{ marginLeft: marginLeft, marginTop: marginTop }}>
      <Image 
        src="/FloatingCircle.svg"
        className="relative z-[-1] -left-1/2"
        width={size}
        height={size}
        alt=""
      />
    </div>
  )
}
