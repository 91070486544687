import { mergeClasses } from "../../lib/_helper";
import DynamicComponent from "../DynamicComponent";

const ContainerPill = ({ blok, shared }) => {
  return (
    <div className={mergeClasses("w-[550px] h-[620px] py-16 px-28 rounded-[180px]",
      blok.pillBackgroundColour ? "bg-" + blok.pillBackgroundColour : "bg-stone-100")}
    >
      {blok.panels.map((panel, i) => (
        <div key={i}>
          <DynamicComponent blok={panel} shared={shared} />
        </div>
      ))}
    </div>
  )
}

export default ContainerPill;