import { HeroIcon } from "../../lib/HeroIcon";
import { PropertyExists } from "../../lib/_helper";
import { mergeClasses } from "../../lib/_helper";

const ContainerHeader = ({ blok }) => {
  return (
    <div>
      <div>
        {PropertyExists(blok?.icon) &&
          <span className={mergeClasses(
            "h-12 w-12 mb-6 rounded-full border-2 border-cto_primary_orange flex items-center justify-center",
            "bg-transparent"
            // blok.iconBackgroundColour ? "bg-" + blok.iconBackgroundColour : "bg-gray-400"
          )}>
            <HeroIcon icon={blok.icon} color='text-cto_primary_orange' size={6} outline />
          </span>
        }

        {PropertyExists(blok?.title) &&
          <h2 className="text-3xl pb-7 font-extrabold tracking-tight text-gray-900">
            {blok.title}
          </h2>
        }

        {PropertyExists(blok?.subtitle) &&
          <h3 className="text-xl font-bold leading-7 text-gray-900">
            {blok.subtitle}
          </h3>
        }

        {PropertyExists(blok?.description) &&
          <p className="text-lg text-gray-500">
            {blok.description}
          </p>
        }
      </div>
    </div>
  )
}

export default ContainerHeader;