export const PropertyExists = (str) => str?.length > 0 ?? false;

export const mergeClasses = (...classes) => classes.filter(Boolean).join(' ')

export const absoluteUrl = (url) => {
  const isEmptyUrl = !url || url == null || url == undefined || url == "" || url == "#"; // Return "#"
  const isAbsoluteUrl = !isEmptyUrl && (url.startsWith("http://") || url.startsWith("https://")); // Return original url
  
  if (isEmptyUrl) {
    // console.log(`[1] was ${url}, now #`)
    return '#';
  }
  else if (isAbsoluteUrl){
    // console.log(`[2] was ${url}, now ${url}`)
    return url;
  }
  else { // Must be a relative url.
    const relativeUrl = url.startsWith("/") ? url : "/" + url ;
    const newUrl = GetRootDomainWithProtocol() + relativeUrl;
    // console.log(`[3] was ${url}, now ${newUrl}`)
    return newUrl;
  }
}

export const GetRootDomainWithProtocol = () => process.env.NEXT_PUBLIC_HTTP + process.env.NEXT_PUBLIC_HOST;

export const removePrefixIfExists = (string, prefix) => {
  if (string.startsWith(prefix)) {
    return string.slice(prefix.length);
  }
  return string;
};

export const removeSuffixIfExists = (string, suffix) => {
  if (string.endsWith(suffix)) {
    return string.slice(0, -suffix.length);
  }
  return string;
};