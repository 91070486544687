import { mergeClasses } from '../lib/_helper';
import Image from "next/legacy/image";
import { HeroIcon } from '../lib/HeroIcon';
import { absoluteUrl } from '../lib/_helper';

const AlternatingWithOptionalTestimonial = ({ blok }) => {
  const imageRight = blok?.imageRight ?? true;

  return (
    <div className={mergeClasses(imageRight ? "" : "bg-gray-100")}>
      <div className="py-24">
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
          <div className={mergeClasses("px-4 max-w-xl mx-auto sm:px-6 lg:max-w-none lg:mx-0 lg:px-0",
            imageRight ? "lg:py-16" : "lg:py-32 lg:col-start-2")}>
            <div>
              <div>
                <span className="h-12 w-12 rounded-md flex items-center justify-center bg-gray-400">
                  <HeroIcon icon={blok.icon} color='text-white' size={6} outline />
                </span>
              </div>
              <div className="mt-6">
                <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                  {blok?.title ?? "{title}"}
                </h2>
                <p className="mt-4 text-lg text-gray-500">
                  {blok?.description ?? "{description}"}
                </p>
                <div className="mt-6">
                  <a
                    href={absoluteUrl(blok?.buttonUrl) ?? "#"}
                    className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-gray-400 hover:bg-gray-500"
                  >
                    {blok?.buttonText ?? "{buttonText}"}
                  </a>
                </div>
              </div>
            </div>
            {blok?.quote && (<div className="mt-8 border-t border-gray-200 pt-6">
              <blockquote>
                <div>
                  <p className="text-base text-gray-500">
                    &ldquo;{blok?.quote ?? "{quote}"}&rdquo;
                  </p>
                </div>
                <footer className="mt-3">
                  <div className="flex items-center space-x-3">
                    <div className="flex-shrink-0">
                      {blok?.quoteByImage?.filename && (<Image
                        className="rounded-full"
                        src={blok?.quoteByImage?.filename}
                        alt={blok?.quoteBy}
                        width={24}
                        height={24}
                      />)
                      }
                    </div>
                    <div className="text-base font-medium text-gray-700">{blok?.quoteBy}</div>
                  </div>
                </footer>
              </blockquote>
            </div>)}
          </div>
          <div className={mergeClasses("mt-12 sm:mt-16 lg:mt-0",
            imageRight ? "" : "lg:col-start-1")}>
            <div className={mergeClasses(imageRight ? "pl-4 -mr-48 sm:pl-6 md:-mr-16" : "pr-4 -ml-48 sm:pr-6 md:-ml-16",
              "lg:px-0 lg:m-0 lg:relative lg:h-full")}>
              {blok?.image?.filename && (<Image
                className={mergeClasses("w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:h-full lg:w-auto lg:max-w-none",
                  imageRight ? "lg:left-0" : "lg:right-0")}
                src={blok?.image?.filename}
                alt={blok?.image?.alt}
                width={550}
                height={550}
              />)
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AlternatingWithOptionalTestimonial;