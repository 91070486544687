import React, { useState } from 'react'
import Autoplay from 'embla-carousel-autoplay'
import EmblaCarousel from '../lib/Carousel/EmblaCarousel';
import { mergeClasses } from '../lib/_helper';
import { sbEditable } from "@storyblok/storyblok-editable";
import { HeroIcon } from "../lib/HeroIcon";

export default function FloatingBanner({ blok, shared }) {
  const [shown, setShown] = useState(true);

  const options = { loop: true }
  let autoplay = undefined;

  const plugins = []
  if (blok.autoplay) {
    autoplay = Autoplay({ delay: blok?.autoplayDelay ? (blok.autoplayDelay * 1000) : 5000 });
    plugins.push(autoplay)
  }

  const colourScheme = {
    arrowColour: blok?.arrowColour,
    dotColour: blok?.dotColour,
    xColour: blok?.xColour,
    bgColour: blok?.bgColour
  };

  const emblaCarousel_colourScheme = {
    arrowColour: colourScheme.arrowColour,
    dotColour: colourScheme.dotColour,
    xColour: colourScheme.xColour,
  }

  return (
    shown && (
      <div className="pointer-events-none fixed inset-x-0 bottom-0 px-2 pb-2 sm:flex sm:justify-center sm:px-6 sm:pb-5 lg:px-8 z-50" {...sbEditable(blok)}>
        <div className={
          mergeClasses(
            "pointer-events-auto flex items-center justify-between gap-x-6 shadow-sm shadow-[#FAF7F380] px-6 py-2.5 rounded-xl sm:py-3 sm:pl-4 sm:pr-3.5",
            colourScheme.bgColour ? ("bg-" + colourScheme.bgColour) : "bg-cto_secondary_dark_grey"
          )
        }>
          <EmblaCarousel slides={blok?.slides ?? []} options={options} plugins={plugins} shared={shared} hideSnaps={true} thin={true} colourScheme={emblaCarousel_colourScheme} minHeight={blok?.minHeight}/>
          <button type="button" className="-m-1.5 flex-none p-1.5" onClick={() => {setShown(false)}}>
            <span className="sr-only">Dismiss</span>
            <HeroIcon 
              icon={"XIcon"}
              color={colourScheme.xColour ? ("text-" + colourScheme.xColour) : 'text-white'}
              size={5}
            />
          </button>
        </div>
      </div>
    )
  )
}
