import React from "react";
import { mergeClasses } from "../_helper";

export const DotButton = ({ index, selected, onClick, colour }) => (
  <button
    aria-label={"Go to slide " + (index + 1)} //change from 0-based to 1-based
    className={mergeClasses("bg-transparent cursor-pointer relative p-0 w-[30px] h-[30px] mx-2 flex items-center opacity",
                            "after:w-full after:h-1 after:rounded-sm after:shadow-sm after:shadow-rich-purple-100",
                            selected 
                              ? colour ? ("after:bg-" + colour) : "after:opacity-100 after:bg-rich-purple-700" 
                              : "after:bg-rich-purple-100"
                            )}
    style={{ outline: 0, border: 0 }}
    type="button"
    onClick={onClick}
  />
);

export const PrevButton = ({ enabled, onClick, colour, isResponsive }) => (
  <div className={
    mergeClasses(
      "flex my-auto mx-2",
      isResponsive ? "hidden lg:flex" : ""
    )
  }>
    <button
      aria-label="Go to the previous slide"
      className={mergeClasses("embla__button w-4 h-4 left-[27px]",
                              "cursor-pointer p-0 justify-center items-center z-10 touch-manipulation",
                              colour ? ("fill-" + colour) : "fill-rich-purple-700",
                              enabled ? "" : "cursor-default opacity-30",
                            )}
      style={{ outline: 0, border: 0 }}
      onClick={onClick}
      disabled={!enabled}
    >
      <svg className="w-full h-full" viewBox="137.718 -1.001 366.563 644">
        <path d="M428.36 12.5c16.67-16.67 43.76-16.67 60.42 0 16.67 16.67 16.67 43.76 0 60.42L241.7 320c148.25 148.24 230.61 230.6 247.08 247.08 16.67 16.66 16.67 43.75 0 60.42-16.67 16.66-43.76 16.67-60.42 0-27.72-27.71-249.45-249.37-277.16-277.08a42.308 42.308 0 0 1-12.48-30.34c0-11.1 4.1-22.05 12.48-30.42C206.63 234.23 400.64 40.21 428.36 12.5z" />
      </svg>
    </button>
  </div>
);

export const NextButton = ({ enabled, onClick, colour, isResponsive }) => (
  <div className={
    mergeClasses(
      "flex my-auto mx-2",
      isResponsive ? "hidden lg:flex" : ""
    )
  }>
    <button
      aria-label="Go to the next slide"
      className={mergeClasses("embla__button w-4 h-4 right-[27px]",
                              "cursor-pointer p-0 justify-center items-center z-10 touch-manipulation",
                              colour ? ("fill-" + colour) : "fill-rich-purple-700",
                              enabled ? "" : "cursor-default opacity-30",
                            )}
      style={{ outline: 0, border: 0 }}
      onClick={onClick}
      disabled={!enabled}
    >
      <svg className="w-full h-full" viewBox="0 0 238.003 238.003">
        <path d="M181.776 107.719L78.705 4.648c-6.198-6.198-16.273-6.198-22.47 0s-6.198 16.273 0 22.47l91.883 91.883-91.883 91.883c-6.198 6.198-6.198 16.273 0 22.47s16.273 6.198 22.47 0l103.071-103.039a15.741 15.741 0 0 0 4.64-11.283c0-4.13-1.526-8.199-4.64-11.313z" />
      </svg>
    </button>
  </div>
);
