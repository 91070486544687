import React from "react";
import { sbEditable } from "@storyblok/storyblok-editable";

const TypeformQuestionnaire = ({ blok }) => {
  return (
    <div {...sbEditable(blok)}>
      <div className="max-w-7xl mx-auto p-4">
          <div
            data-tf-widget={blok.typeformFormId}
            data-tf-opacity="100"
            data-tf-iframe-props={`title=${blok.title}`}
            data-tf-transitive-search-params
            data-tf-medium="snippet"
            className="h-[600px] w-full"
          />
      </div>
    </div>);
};

export default TypeformQuestionnaire;
