import Image from "next/legacy/image";
import { sbEditable } from "@storyblok/storyblok-editable";

const Testimonial = ({ blok }) => {
  return (
    <section
      className="py-12 bg-transparent overflow-hidden md:py-20 lg:py-24"
      {...sbEditable(blok)}
    >
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="relative">
          <blockquote className="mt-6">
            <div className="max-w-3xl mx-auto text-center text-2xl leading-9 font-normal text-gray-600">
              <p>
                <span className="text-cto_primary_purple text-4xl">〝</span>
                {blok?.quote}
                <span className="text-cto_primary_purple text-4xl">〞</span>
              </p>
            </div>
            <footer className="mt-8">
              <div className="md:flex md:items-center md:justify-center">
                {blok?.photo?.filename?.length > 0 && (
                  <div className="relative md:flex-shrink-0 mx-auto md:mx-0 w-[60px] h-[60px]">
                    <Image
                      className="mx-auto rounded-full"
                      src={blok?.photo?.filename}
                      alt={blok?.photo?.alt}
                      layout='fill'
                      objectFit='cover'
                    />
                  </div>
                )}
                <div className="mt-3 text-center md:mt-0 md:mx-4 md:flex md:items-center">
                  <div className="text-base font-medium text-gray-700">
                    {blok?.name}
                  </div>
                  {blok?.role?.length > 0 && (
                    <>
                      <svg
                        className="hidden md:block mx-1 h-5 w-5 text-cto_primary_purple"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M11 0h3L9 20H6l5-20z" />
                      </svg>
                      <div className="text-base font-medium text-gray-500">
                        {blok?.role}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </footer>
          </blockquote>
        </div>
      </div>
    </section>
  );
};

export default Testimonial;
