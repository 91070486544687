/* eslint-disable @next/next/no-img-element */

import React, { useMemo } from "react";

//Introduced due to this 'next/image' package issue => https://github.com/vercel/next.js/issues/65161
const NextImage = React.forwardRef((props, ref) => {
  const { src, alt, className, width, height, ...rest } = props;

  const imageSrc = useMemo(() => {
    if (!src) {
      return undefined;
    }

    if (typeof src === "string") {
      return src;
    }

    if ("default" in src) {
      return src.default.src;
    }

    return src.src;
  }, [src]);

  return (
    <img
      ref={ref}
      src={imageSrc}
      alt={alt}
      width={width}
      height={height}
      className={["absolute h-full w-full left-0 top-0 right-0 bottom-0", className].join(" ")}
    />
  );
});

NextImage.displayName = "Image";

export default NextImage;