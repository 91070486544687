export const DCurve = ({ colour }) => (
  <svg
    height="100%"
    version="1.1"
    viewBox="0 0 1000 1000"
    width="100%"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Layer-1">
      <path d="M260.938 0C294.142 38.4567 314.25 88.5174 314.25 143.312C314.25 196.62 295.261 245.477 263.656 283.5L365.562 283.5C367.213 283.5 368.562 282.159 368.562 280.5L368.562 3C368.563 1.34149 367.213 0 365.562 0L260.938 0Z" fill={colour || "#faf7f3"} opacity="1" stroke="none" />
    </g>
  </svg>
)