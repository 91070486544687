import { sbEditable } from "@storyblok/storyblok-editable";
import { mergeClasses } from "../lib/_helper";
import ValuesCard from "./ValuesCard.js";

const ValuesCardPanel = ({ blok }) => {
  return (
    <div
      className={mergeClasses(
        blok.backgroundColour ? "bg-" + blok.backgroundColour : transparent,
        "relative py-20 px-4 sm:px-6 lg:px-8"
      )}
      {...sbEditable(blok)}
    >
      <div className="text-center">
        <h2
          className={mergeClasses(
            blok.titleColour ? "text-" + blok.titleColour : "text-gray-900",
            "text-3xl py-12 font-montserrat tracking-tight sm:text-4xl"
          )}
        >
          {blok.title}
        </h2>
      </div>
      <div className="relative max-w-7xl mx-auto w-full flex justify-center">
        <div
          className={mergeClasses(
            "max-w-none",
            "sm:grid-cols-1 md:grid-cols-2",
            blok.columns ? "lg:grid-cols-" + blok.columns : "lg:grid-cols-3",
            "max-w-lg grid lg:max-w-none"
          )}
        >
          {blok?.values?.map((card) => (
            <ValuesCard blok={card} key={card.title} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ValuesCardPanel;
