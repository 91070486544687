import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/outline";
import { sbEditable } from "@storyblok/storyblok-editable";
import { mergeClasses } from "../lib/_helper";

const Faq = ({ blok }) => {
  return (
    <div {...sbEditable(blok)}>
      <Disclosure as="div" key={blok.question} className="pt-6">
        {({ open }) => (
          <>
            <dt className="text-lg">
              <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                <span className="font-medium text-gray-700">
                  {blok.question}
                </span>
                <span className="ml-6 h-7 flex items-center">
                  <ChevronDownIcon
                    className={mergeClasses(
                      open ? "-rotate-180" : "rotate-0",
                      "h-6 w-6 transform"
                    )}
                    aria-hidden="true"
                  />
                </span>
              </Disclosure.Button>
            </dt>
            <Disclosure.Panel as="dd" className="mt-2 pr-12">
              <p className="text-base text-gray-500">{blok.answer}</p>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
};

export default Faq;
