import Link from "next/link";
import swal from "sweetalert";
import React, { useState, useRef } from "react"
import EmailValidator from "email-validator";
import { sbEditable } from "@storyblok/storyblok-editable";
import { ExternalLinkIcon, MailIcon, PhoneIcon, XIcon } from "@heroicons/react/outline";
import FileUploadComponent from "../lib/Contact/FileUploadComponent";

import { absoluteUrl } from "../lib/_helper";

const Contact = ({ blok }) => {
  const [uploadedFile, setUploadedFile] = useState(null);

  const submitContact = async (event) => {
    event.preventDefault();

    const target = event?.target
    const email = target?.emailAddress.value;
  
    // belt and braces email validation check
    if (!EmailValidator.validate(email)) {
      console.warn("Invalid email submitted");
      return;
    }

    const formData = new FormData()
    formData.append("file", uploadedFile)
    formData.append("firstName", target?.firstName?.value)
    formData.append("surname", target?.surname?.value)
    formData.append("email", email)
    formData.append("linkedIn", target?.linkedIn?.value)
    formData.append("phoneNumber", target?.phoneNumber?.value)
    formData.append("message", target?.message?.value)
    formData.append("hubspotId", target?.hubspotId?.value)
    formData.append("formType", target?.formType?.value)
  
    const response = await fetch("/api/contact", {
      body: formData,
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "POST",
    });
  
    swal({
      title: "Thanks for reaching out, we'll be in touch very soon!",
      // text: result.email,
      icon: "success",
      button: "close",
    });
    // document.getElementById("contact-form").reset();
  };

  return (
    <div className="relative bg-transparent" {...sbEditable(blok)}>
      <div className="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
        <div className="py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
          <div className="max-w-lg mx-auto">
            <h2 className="text-2xl font-montserrat tracking-tight text-cto_primary_purple sm:text-3xl">
              {blok.title}
            </h2>
            <p className="mt-3 text-lg leading-6 text-gray-500">
              {blok.description}
            </p>

            <dl className="mt-8 text-base text-gray-500">
              {blok?.address?.length > 0 && (
                <div>
                  <dt className="sr-only">Postal address</dt>
                  <dd>{blok.address}</dd>
                </div>
              )}
              {blok?.phone?.length > 0 && (
                <div className="mt-6">
                  <dt className="sr-only">Phone number</dt>
                  <dd className="flex">
                    <PhoneIcon
                      className="flex-shrink-0 h-6 w-6 text-gray-400"
                      aria-hidden="true"
                    />
                    <span className="ml-3">{blok.phone}</span>
                  </dd>
                </div>
              )}
              {blok?.email?.length > 0 && (
                <div className="mt-3">
                  <dt className="sr-only">Email</dt>
                  <dd className="flex">
                    <MailIcon
                      className="flex-shrink-0 h-6 w-6 text-gray-400"
                      aria-hidden="true"
                    />
                    <span className="ml-3">{blok.email}</span>
                  </dd>
                </div>
              )}
            </dl>
            {blok.hideCareersLink || (
              <p className="mt-6 text-base text-gray-500">
                Looking for careers?{" "}
                <Link
                  href={absoluteUrl("/careers")}
                  className="font-medium text-gray-700 underline">
                  
                    View all job openings.
                  
                </Link>
              </p>
            )}
          </div>
        </div>
        <div className="py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
          <div className="max-w-lg mx-auto lg:max-w-none">
            <form id="contact-form" className="grid grid-cols-1 gap-y-6" onSubmit={submitContact}>
              <input type="hidden" id="hubspotId" name="hubspotId" value={blok.hubspotID} />
              <input type="hidden" id="formType" name="formType" value={blok.formType} />
              <div>
                <label htmlFor="fullName" className="sr-only">
                  First name
                </label>
                <input
                  type="text"
                  name="firstName"
                  id="firstName"
                  autoComplete="first name"
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-full"
                  placeholder="First name"
                />
              </div>
              <div>
                <label htmlFor="fullName" className="sr-only">
                  Surname
                </label>
                <input
                  type="text"
                  name="surname"
                  id="surname"
                  autoComplete="surname"
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-full"
                  placeholder="Surname"
                />
              </div>
              <div>
                <label htmlFor="emailAddress" className="sr-only">
                  Email
                </label>
                <input
                  id="emailAddress"
                  name="emailAddress"
                  type="email"
                  autoComplete="email"
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-full"
                  placeholder="Email"
                />
              </div>
              <div>
                <label htmlFor="phoneNumber" className="sr-only">
                  Phone
                </label>
                <input
                  type="text"
                  name="phoneNumber"
                  id="phoneNumber"
                  autoComplete="tel"
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-full"
                  placeholder="Phone"
                />
              </div>

              {blok.hideLinkedIn || (
                <div>
                  <label htmlFor="linkedIn" className="sr-only">
                    LinkedIn
                  </label>
                  <input
                    type="text"
                    name="linkedIn"
                    id="linkedIn"
                    autoComplete="linkedin"
                    className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-full"
                    placeholder="LinkedIn"
                  />
                </div>
              )}
              <div>
                <label htmlFor="message" className="sr-only">
                  Message
                </label>
                <textarea
                  id="message"
                  name="message"
                  rows={4}
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border border-gray-300 rounded-3xl"
                  placeholder="Message"
                  defaultValue={""}
                />
              </div>

              {blok.showCVUpload && (<div>
                <FileUploadComponent 
                  updateFilesCb={(files) => {
                    files ? setUploadedFile(files[0]) : setUploadedFile(null)}
                  }
                />
              </div>
              )}
              <div>
                <button
                  type="submit"
                  className="inline-flex justify-center py-3 px-6 border border-transparent shadow-sm text-base font-medium rounded-full text-white bg-cto_primary_orange hover:bg-cto_primary_pink focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
