import DynamicComponent from "../DynamicComponent";
import { sbEditable } from "@storyblok/storyblok-editable";

export default function ProfileContainer({ blok, shared }) {
  return (
    <div {...sbEditable(blok)}>
      <div className="max-w-7xl mx-auto py-4 px-4 text-center sm:px-6 lg:px-8 lg:py-8">
        <div className="space-y-12">
          <ul
            role="list"
            className="mx-auto space-y-16 sm:grid sm:grid-cols-2 sm:gap-16 sm:space-y-0 lg:grid-cols-3 lg:max-w-5xl"
          >
            {blok.profiles.map((panel, i) => {
              return (
                <div key={i}>
                  <DynamicComponent blok={panel} shared={shared} />
                </div>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
}
