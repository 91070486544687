import React from 'react'
import { sbEditable } from "@storyblok/storyblok-editable";
import EmblaCarouselFree from '../lib/Carousel/EmblaCarouselFree';

export default function CarouselFree ({ blok }) {
  if (blok?.slides)
  {
    return (
      <div {...sbEditable(blok)} className="bg-cto_secondary_light_warm_grey">
        <EmblaCarouselFree slides={blok.slides}/>
      </div>
    )
  }
  return;
}
