import React, { useEffect } from "react";
import { sbEditable } from "@storyblok/storyblok-editable";
import Router from 'next/router';
import Loading from "../components/Loading";
import { getRootDomainFromHost } from "../lib/subdomain";

const Redirect = ({ blok }) => {
  useEffect(() => {
    const redirect = blok.Redirect
    if (blok != null) {
      if (redirect.linktype == "url") {
        Router.replace(redirect.url)
      }
      else if (redirect.linktype == "story") {
        const storyUrl = "/" + redirect.story.url;
        const appPrefix = "/app/"
        if (storyUrl.startsWith(appPrefix)) {
          const currentHost = getRootDomainFromHost(window.location.host);
          const subdomain = storyUrl.substring(appPrefix.length)

          Router.push(`http://${subdomain}.${currentHost}`);
        }
        else {
          Router.replace("/" + redirect.story.url)
        }
      }
      else {
        throw new Error("Unrecognised Link Type Provided...")
      }
    }
  }, [blok, blok?.Redirect?.cached_url]);

  return (
    <div {...sbEditable(blok)}>
      <Loading/>
    </div>
  );
};

export default Redirect;
