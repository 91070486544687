import { sbEditable } from "@storyblok/storyblok-editable";
import { mergeClasses } from "../lib/_helper";
import DynamicComponent from "./DynamicComponent.js";

const ServiceCardPanel = ({ blok }) => {
  return (
    <div
      className="relative bg-transparent pt-8 pb-8 px-4 sm:px-6 lg:pt-12 lg:pb-20 lg:px-8"
      {...sbEditable(blok)}
    >
      <div className="absolute inset-0">
        <div
          className={mergeClasses(
            blok.colourBackdrop
              ? "bg-" + blok.colourBackdrop
              : "bg-transparent",
            "h-2/5 sm:h-2/5"
          )}
        />
      </div>
      {blok?.title?.length > 0 && (
        <h2 className="text-center text-3xl font-normal font-montserrat text-gray-700 sm:text-4xl mb-6 py-12">
          {blok.title}
        </h2>
      )}
      <div className="relative max-w-7xl mx-auto w-full flex justify-center bg-cto_secondary_light_warm_grey">
        <div
          className={mergeClasses(
            "max-w-none",
            "sm:grid-cols-1 md:grid-cols-2",
            blok.columns ? "lg:grid-cols-" + blok.columns : "lg:grid-cols-3",
            "max-w-lg grid lg:max-w-none"
          )}
        >
          {blok?.services?.map((card, index) => (
            <DynamicComponent blok={card} key={index} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ServiceCardPanel;
