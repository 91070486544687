import React from "react";
import { sbEditable } from "@storyblok/storyblok-editable";
import { mergeClasses } from "../lib/_helper";

const Stats = ({ blok }) => {
  return (
    <div
      className={mergeClasses(
        blok.backgroundColour
          ? "bg-" + blok.backgroundColour
          : "bg-cto_primary_purple"
      )}
      {...sbEditable(blok)}
    >
      <div className="max-w-7xl mx-auto py-5 px-4 sm:py-16 sm:px-6 lg:px-8 lg:py-20 text-cto_secondary_navy_blue">
        {blok?.title?.length > 0 && (
          <div className="max-w-4xl mx-auto text-center pb-10">
            <h3 className="text-4xl font-monserrat sm:text-4xl">
              {blok.title}fsdsf
            </h3>
            <p className="mt-3 text-xl font-dm-sans sm:mt-4">
              {blok.text}sdfsdf
            </p>
          </div>
        )}
        <dl
          className={mergeClasses("grid",
            blok?.stats?.length > 0
              ? "lg:grid-cols-" + blok?.stats?.length
              : "lg:grid-cols-1",
            "mt-10 grid-cols-1 text-center gap-y-8"
          )}
        >
          {blok?.stats?.map((stat, index) => (
            <div className="flex flex-col" key={index}>
              <dt className="order-2 mt-2 text-xs leading-6 font-medium uppercase">
                {stat?.text}
              </dt>
              <dd className="order-1 text-5xl font-bold text-cto_primary_orange bg-invisible">
                {stat?.stat}
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  );
};

export default Stats;
