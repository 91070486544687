import { sbEditable } from "@storyblok/storyblok-editable";
import "@animxyz/core";
import { XyzTransition } from "@animxyz/react";
import { ResolveRichText } from "../lib/storyblok";
import { mergeClasses } from "../lib/_helper";
import Image from "next/legacy/image";
import { Link } from 'react-scroll'

const SectionHero = ({ blok }) => {

  const toggled = true;

  return (
    <>
      <div
        className={mergeClasses(
          blok.backgroundColour
            ? "bg-" + blok.backgroundColour
            : "bg-cto_secondary_navy_blue",
          "grid grid-cols-1 lg:grid-cols-2 pt-12 pb-20"
        )}
        {...sbEditable(blok)}
      >
        <div className="ml-auto lg:max-w-2xl w-full px-4 sm:px-6 lg:px-8">
          {blok?.title?.length > 0 && (
            <XyzTransition
              appearVisible={{ threshold: 0, rootMargin: "-100px" }}
              xyz="fade left-5 duration-8 ease"
            >
              {toggled && (
                <h1 className="font-montserrat font-normal text-6xl py-6">
                  <span className="text-white">
                    {blok.title}
                  </span>
                </h1>
              )}
            </XyzTransition>
          )}
          <h2 className="font-montserrat text-xl py-8">
            <span
              className="articleCopy text-white"
              dangerouslySetInnerHTML={{
                __html: ResolveRichText.render(blok?.subtitle),
              }}
            />
          </h2>
          {blok?.linkText?.length > 0 && (
            <Link
              activeClass="nav-active"
              className="font-dm-sans cursor-pointer my-4 w-full inline-flex items-center justify-center border border-transparent rounded-full sm:w-auto py-4 font-medium text-base px-10 text-white bg-cto_primary_orange hover:text-cto_secondary_navy_blue hover:shadow hover:shadow-black hover:bg-cto_secondary_light_warm_grey"
              to={"scrollToPoint"}
              spy={true}
              smooth={true}
              duration={500}
              offset={-100}
            >
              {blok.linkText}
            </Link>
          )}
        </div>
        {blok?.image?.filename?.length > 0 && (
          <div className="place-content-center my-auto mr-auto pl-12 px-12 order-first lg:order-last flex justify-center">
            <Image
              className="w-full"
              src={blok?.image?.filename}
              alt=""
              height={400}
              width={600}
            ></Image>
          </div>
        )}
      </div>
      <div id="scrollToPoint"></div>
    </>
  );
};

export default SectionHero;
