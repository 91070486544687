import { HeroIcon } from "../../lib/HeroIcon";
import { PropertyExists } from "../../lib/_helper";
import { mergeClasses } from "../../lib/_helper";

const ContainerIconPoint = ({ blok }) => {
  return (
    <div className="mt-10 mr-16">
      <div className="flex">
        <div className="flex-1 w-16">
          <span className={mergeClasses("h-12 w-12 rounded-md flex items-center justify-center",
            blok.iconBackgroundColour ? "bg-" + blok.iconBackgroundColour : "bg-gray-400")}>
            <HeroIcon icon={blok?.icon} color='text-white' size={6} outline />
          </span>
        </div>
        <div className="w-96 mx-5 flex-grow">

          {PropertyExists(blok?.title) &&
            <h2 className="leading-6 text-lg font-medium tracking-tight text-gray-900 break-words">
              {blok.title}
            </h2>
          }

          {PropertyExists(blok?.description) &&
            <p className="mt-4 text-base leading-6 font-normal text-gray-500 break-words">
              {blok.description}
            </p>
          }

        </div>
      </div>
    </div>
  )
}

export default ContainerIconPoint;