import Image from "next/legacy/image";
import Link from "next/link";
import Author from "../Author";
import { absoluteUrl } from "../../lib/_helper";
import { ResolveRichText } from "../../lib/storyblok";

const ArticleCard = ({ blok, useDefaultImage }) => {
  const article = blok?.content?.body[0];

  return (
    <div className="flex flex-col rounded-sm shadow-lg overflow-hidden h-full">

      {(useDefaultImage || article?.image?.filename?.length > 0) && (
        (<Link
          href={absoluteUrl(blok?.full_slug)}
          aria-label={"Go to " + (article?.title ?? "this article")}>

          <Image
            className="w-full object-cover cursor-pointer"
            src={useDefaultImage ? "/brandpattern.jpg" : article?.image?.filename}
            alt={useDefaultImage ? "" : article?.image?.alt ?? ""}
            width={512}
            height={192}
          />

        </Link>))
      }
      {article && (
        <div className="flex-1 bg-white pt-6 px-6 flex flex-col justify-between">
          <div className="flex-1">
            <p className="text-sm font-medium text-indigo-600">
              {article?.category}
            </p>
            <Link href={absoluteUrl(blok?.full_slug)} className="block mt-2">

              <p className="text-xl font-semibold text-gray-900">
                {article?.title}
              </p>
              <span
                className="mt-3 text-base text-gray-500"
                dangerouslySetInnerHTML={{
                  __html: ResolveRichText.render(article?.bodyText),
                }}
              />

            </Link>
          </div>
          <div className="flex items-center">
            <Author
              author={article.author}
              publishedDate={article.publishedDate}
              readingTime={article.readingTime}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ArticleCard;
