import Image from "next/legacy/image";
import Link from "next/link";
import { ResolveRichText } from "../lib/storyblok";
import { sbEditable } from "@storyblok/storyblok-editable";
import { HeroIcon } from "../lib/HeroIcon";

const ValuesCard = ({ blok }) => {
  return (
    <div {...sbEditable(blok)}>
      <div className="flex flex-col bg-transparent ">
        <div className="flex-1 px-6 py-4 flex flex-col justify-between">
          <div className="flex-shrink-0 px-4 pt-8">
            {blok?.icon.length > 0 && (
              <HeroIcon
                icon={blok?.icon}
                color="text-cto_primary_orange"
                size={12}
                outline
              />
            )}
          </div>
          <div className="pt-4 px-4">
            <span className="font-montserrat font-bold text-xl text-cto_secondary_navy_blue">
              {blok?.title}
            </span>
          </div>
          <div className="flex-1 pt-4 px-4">
            <span
              className="text-lg articleCopy"
              dangerouslySetInnerHTML={{
                __html: ResolveRichText.render(blok?.body),
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ValuesCard;
