import * as SolidIcons from '@heroicons/react/solid';
import * as OutlineIcons from '@heroicons/react/outline';

export const HeroIcon = (props) => {
  const { icon, color, size, outline = false, light=false } = props;

  const { ...icons } = outline ? OutlineIcons : SolidIcons;
  const Icon = icons[icon];

  if (Icon == null) {
    return null;
  }

  const classes = [
    `${color ? color : 'text-black'}`,
    `h-${size ? size : 6}`,
    `w-${size ? size : 6}`,
    `heroicon-sw-${light ? 1 : 2}`,
  ];

  return (
    <Icon 
      className={classes.join(' ')} 
      aria-hidden="true" 
    />
  );
};