import React, { useState, useEffect, useCallback } from "react";
import { DotButton, PrevButton, NextButton } from "./EmblaCarouselButtons";
import useEmblaCarousel from "embla-carousel-react";
import DynamicComponent from '../../components/DynamicComponent';
import { mergeClasses } from "../_helper";

const EmblaCarousel = ({ options, plugins, slides, shared, hideSnaps = false, thin = false, colourScheme = null, minHeight = null }) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({ ...options, skipSnaps: false }, plugins);
  const emblaHasInit = Boolean(emblaApi);
  const autoplay = emblaApi?.plugins()?.autoplay;

  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState([]);

  const scrollPrev = useCallback(() => emblaApi && emblaApi.scrollPrev(), [emblaApi]);
  const scrollNext = useCallback(() => emblaApi && emblaApi.scrollNext(), [emblaApi]);
  const scrollTo = useCallback((index) => emblaApi && emblaApi.scrollTo(index), [emblaApi]);

  const autoplayPlay = useCallback(() => emblaHasInit ? autoplay?.play() : null, [emblaHasInit, autoplay])
  const autoplayStop = useCallback(() => emblaHasInit ? autoplay?.stop() : null, [emblaHasInit, autoplay])
  const autoplayReset = useCallback(() => emblaHasInit ? autoplay?.reset() : null, [emblaHasInit, autoplay])

  const onSelect = useCallback(() => {
    if (!emblaApi) {
      return;
    }

    if (autoplay != null) {
      autoplayReset();
    }
    
    setSelectedIndex(emblaApi.selectedScrollSnap());
    setPrevBtnEnabled(emblaApi.canScrollPrev());
    setNextBtnEnabled(emblaApi.canScrollNext());
  }, [emblaApi, setSelectedIndex, autoplay, autoplayReset]);

  useEffect(() => {
    if (!emblaApi) {
      return;
    }

    onSelect();
    setScrollSnaps(emblaApi.scrollSnapList());
    emblaApi.on("select", onSelect);
    if (autoplay != null) {
      emblaApi.on("reInit", () => autoplayPlay())
      emblaApi.on("pointerUp", () => autoplayPlay());  // <-- Play again as soon as the user has released the pointer
      emblaApi.on("pointerDown", () => autoplayStop()) // <-- Stop when the user is clicking on the carousel
    }
  }, [emblaApi, setScrollSnaps, onSelect, autoplay, autoplayPlay, autoplayStop]);
  
  const isResponsive = true;
  return (
    <>
      <div className={
        mergeClasses(
          "embla relative max-w-7xl mx-auto flex",
          thin 
            ? [isResponsive ? "px-0" : "px-12", "py-0"].join(" ")
            : [isResponsive ? "px-0" : "px-5", "py-5"].join(" "),
          )
        }
      >
        <PrevButton onClick={scrollPrev} enabled={prevBtnEnabled} colour={colourScheme?.arrowColour} isResponsive={isResponsive}/>
        <div
          className="embla__viewport overflow-hidden w-full" 
          style={minHeight ? { minHeight: (minHeight) + "px" } : {}}
          ref={emblaRef}
        >
          <div 
            className="embla__container flex select-none"
            style={{ WebkitTouchCallout: 'none', KhtmlUserSelect: 'none', WebkitTapHighlightColor: 'transparent'}}
          >
            {slides.map((slide, index) => (
              <div className="embla__slide relative min-w-full flex items-center justify-center" key={index}>
                <div className={
                  mergeClasses(
                    "relative overflow-hidden",
                    isResponsive ? "mx-2 lg:mx-8" : "mx-8"
                  )
                }>
                  <DynamicComponent blok={slide} shared={shared} />
                </div>
              </div>
            ))}
          </div>
        </div>
        <NextButton onClick={scrollNext} enabled={nextBtnEnabled} colour={colourScheme?.arrowColour} isResponsive={isResponsive}/>
      </div>
      {(!hideSnaps) &&
          (<div className="flex justify-center" style={{ listStyle: 'none' }}>
            {scrollSnaps.map((_, index) => (
              <DotButton
                key={index}
                index={index}
                colour={colourScheme?.xColour}
                selected={index === selectedIndex}
                onClick={() => scrollTo(index)}
              />
            ))}
          </div>)
      }
    </>
  );
};

export default EmblaCarousel;
