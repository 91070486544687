import "@animxyz/core";
import { sbEditable } from "@storyblok/storyblok-editable";
import { XyzTransition } from "@animxyz/react";

import { HeroIcon } from "../lib/HeroIcon";
import { mergeClasses } from "../lib/_helper";
import { ResolveRichText } from "../lib/storyblok";
import * as Scroll from 'react-scroll';
import { Link, Button, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

const ServiceHero = ({ blok }) => {
  const toggled = true;
  const service = blok.service.content;

  return (
    <>
      <div
        className={mergeClasses(
          "flex mx-auto justify-center",
          blok.backgroundColour
            ? "bg-" + blok.backgroundColour
            : "bg-cto_secondary_navy_blue"
        )}
        {...sbEditable(blok)}
      >
        <div className="relative">
          <div>
            <div className="lg:hidden h-auto overflow-hidden flex justify-center mx-[-20%] mb-16 lg:mt-16 pb-20">
              <div className={mergeClasses("rounded-full object-cover max-w-[896px] h-[896px] mt-[-600px] w-full flex items-center justify-center",
                blok.iconBackgroundColour
                  ? "bg-" + blok.iconBackgroundColour
                  : "bg-transparent"
              )}>
                <div className="mt-[600px]">
                  <HeroIcon
                    icon={service?.icon}
                    color="text-cto_primary_purple"
                    size={56}
                    outline
                    light
                    aria-hidden="true"
                  />
                </div>
              </div>
            </div>
            <div className="max-w-7xl mt-[-150px] lg:grid lg:grid-cols-2 overflow-hidden py-8">
              <div className="flex items-center">
                <div className="ml-auto max-w-2xl min-w-xl px-6 lg:pl-8 lg:pr-16 h-min">
                  {service?.title?.length > 0 && (
                    <XyzTransition
                      appearVisible={{ threshold: 0, rootMargin: "-100px" }}
                      xyz="fade left-5 duration-8 ease"
                    >
                      {toggled && (
                        <h1 className="font-montserrat font-normal text-5xl py-4">
                          <span className="text-white">{service.title}</span>
                        </h1>
                      )}
                    </XyzTransition>
                  )}

                  <h2 className="font-montserrat text-xl py-8 articleCopy text-white">
                    {service?.bodyText}
                  </h2>
                  {service?.urlText?.length > 0 && (
                    <Link
                      className="font-dm-sans my-4 w-full inline-flex items-center justify-center border border-transparent rounded-full sm:w-auto py-4 font-medium text-base px-10 text-white bg-cto_primary_orange hover:text-cto_secondary_navy_blue  hover:shadow hover:shadow-black hover:bg-cto_secondary_light_warm_grey"
                      to={"scrollToPoint"}
                      spy={true}
                      smooth={true}
                      duration={500}
                      offset={-100}
                    >
                      {service.urlText}
                    </Link>
                  )}
                </div>
              </div>
              <div className={mergeClasses("hidden lg:block lg:col-span-1")}>
                <div className="h-[800px] w-[896px]">
                  <div
                    className={mergeClasses(
                      "rounded-full object-cover w-[896px] h-[896px] flex justify-center items-center mt-[-110px]",
                      blok.iconBackgroundColour
                        ? "bg-" + blok.iconBackgroundColour
                        : "bg-transparent",
                      "mr-[-282px]"
                    )}
                  >
                    <div className="mr-[282px]">
                      <HeroIcon
                        icon={service?.icon}
                        color="text-cto_primary_purple"
                        size={56}
                        outline
                        light
                        aria-hidden="true"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={mergeClasses("absolute inset-0 w-[50vw]",
            "ml-[99%] mt-[-150px]",
            "hidden lg:block",
            blok.iconBackgroundColour
              ? "bg-" + blok.iconBackgroundColour
              : "bg-transparent")} />
        </div>
      </div>
      <div id="scrollToPoint"></div>
    </>
  );
};

export default ServiceHero;
