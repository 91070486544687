/* eslint-disable @next/next/no-img-element */
import React, { useState, useEffect, useCallback } from "react";
import { DotButton, PrevButton, NextButton } from "./EmblaCarouselButtons";
import useEmblaCarousel from "embla-carousel-react";
import Image from "next/legacy/image";

const EmblaCarouselFree = ({ slides }) => {
  const [viewportRef, embla] = useEmblaCarousel({
    containScroll: "keepSnaps",
    dragFree: true    
  });

  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);

  const [scrollSnaps, setScrollSnaps] = useState([NaN]);
  const [scrollSnapsConfirmed, setScrollSnapsConfirmed] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);
  const scrollTo = useCallback((index) => embla && embla.scrollTo(index), [embla]);

  const onSelect = useCallback(() => {
    if (!embla){
      return;
    }

    setSelectedIndex(embla.selectedScrollSnap());
    setPrevBtnEnabled(embla.canScrollPrev());
    setNextBtnEnabled(embla.canScrollNext());
  }, [embla]);

  // scrollSnapList for the carousel is rather buggy for first load (for a users first time accessing the website)
  const fetchScrollSnapList = useCallback(() => {
    const ssl = embla?.scrollSnapList()
    const sslLength = ssl.length
    
    const isInvalid = sslLength == 1 && Number.isNaN(ssl[0])
    const isNotFullyLoaded = sslLength > 1 && ssl[sslLength-2] == 1 //if the scroll position of the second last object is 1.00 (the end), then it's not finished loading...

    if (isInvalid || isNotFullyLoaded) {
      embla.reInit()
      console.log("refreshing...")
      setTimeout(() => fetchScrollSnapList(), 1000)
    }

    setScrollSnaps(ssl ?? [])
    }, [embla])

  useEffect(() => {
    if (!embla) {
      return;
    }
    
    onSelect();
    fetchScrollSnapList()
    embla.on("select", onSelect);
  }, [embla, fetchScrollSnapList, onSelect]);
  

  return (
    <>
      <div className="relative p-5 max-w-7xl w-full mx-auto">
        <div className="overflow-hidden w-full" ref={viewportRef}>
          <div className="flex mx-8">
            {slides.map((slide, index) => (
              <div className="relative mx-2.5 w-fit border-rich-purple-300 border-4" key={index}>
                <div className="relative overflow-hidden h-[400px] bg-white">
                  <img
                    className="block h-full object-contain w-auto min-h-full min-w-full max-w-none"
                    src={slide.filename}
                    alt={slide.alt}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
        <PrevButton onClick={scrollPrev} enabled={prevBtnEnabled} />
        <NextButton onClick={scrollNext} enabled={nextBtnEnabled} />
      </div>
        <div className="flex justify-center" style={{ listStyle: 'none' }}>
        {scrollSnaps.map((_, index) => (
          <DotButton
            key={index}
            index={index}
            selected={index === selectedIndex}
            onClick={() => scrollTo(index)}
          />
        ))}
      </div>
    </>
  );
};

export default EmblaCarouselFree;