import Link from 'next/link'
import Image from "next/legacy/image";

import { absoluteUrl } from '../../../lib/_helper';

function MainLogo() {
  return (
    (<Link
      href={absoluteUrl("/")}
      className="flex focus-visible:opacity-60 focus-visible:outline-0 shrink-0">

      <span className="sr-only">CTO labs</span>
      <Image
        src="/CTOLabs_Logotype_White.svg"
        alt="cto labs logo"
        width={150}
        height={34}
      />

    </Link>)
  );
}

export default MainLogo;
