import { sbEditable } from "@storyblok/storyblok-editable";
import { mergeClasses } from "../lib/_helper";

const SemiCircleWithOffsetImage = ({ blok }) => {
  const isImageLeft = blok.isImageLeft ?? false;

  return (
    <div
      className={mergeClasses(
        "h-full flex items-center",
        blok.backgroundColour ? "bg-" + blok.backgroundColour : "bg-transparent",
      )}
    >
      <div className={mergeClasses("mx-auto max-w-7xl")}>
        {blok && (
          <div className="relative">
            <div
              className={mergeClasses(
                "overflow-hidden pb-8 lg:pb-0",
                blok.overflowTop && blok.roundedTop ? "mt-0 lg:-mt-10" : "",
                blok.overflowBottom && blok.roundedBottom ? "mb-0 lg:-mb-10" : ""
              )}
              {...sbEditable(blok)}
            >
              <div className="lg:hidden h-auto flex justify-center">
                <div className="relative max-w-[896px] w-screen translate-x-1/2 -left-1/2">
                  <div className="h-[600px]">
                    <div className={mergeClasses("rounded-full object-cover max-w-[896px] h-[896px] mt-[-600px] w-full flex items-center justify-center",
                                                  blok.iconBackgroundColour
                                                    ? "bg-" + blok.iconBackgroundColour
                                                    : "bg-transparent"
                                                  )}>
                    </div>
                  </div>
                  <div className="mt-[150px] w-full flex items-center justify-center px-10">
                      <img
                        className="object-cover w-full h-full aspect-1 p"
                        style={{borderRadius: ((blok?.imageBorderRadius ?? 0) + "px")}}
                        src={blok.image?.filename}
                        alt={""}
                        />
                    </div>
                  </div>
              </div>
              <div
                className={mergeClasses(
                  "lg:grid lg:grid-cols-4",
                  blok.roundedBottom ? "" : "lg:h-[800px]",
                  blok.roundedTop ? "" : "lg:mt-[-96px]"
                )}
              >
                <div
                  className={mergeClasses(
                    "hidden lg:block lg:col-span-2",
                    isImageLeft ? "order-first" : "order-last"
                  )}
                >
                  <div className="h-[896px] w-[896px]">
                    <div
                      className={mergeClasses(
                        "object-cover w-[896px] h-[896px] ml-[200px] flex pl-[145px] pr-[35px]",
                        blok.iconBackgroundColour
                          ? "bg-" + blok.iconBackgroundColour
                          : "bg-transparent",
                        isImageLeft ? "ml-[-340px] justify-end rounded-r-full" : "mr-[-340px] rounded-l-full"
                      )}
                    >
                      

                    </div>
                    <div className="-mt-[896px] h-[896px] w-[896px] overflow-hidden flex items-center justify-center">
                      <div 
                        className={mergeClasses(
                          isImageLeft ? "ml-48" : "mr-56",
                          "max-h-[600px] max-w-[600px] w-full h-full aspect-1 overflow-hidden"
                        )}
                        style={{borderRadius: ((blok?.imageBorderRadius ?? 0) + "px")}}>
                        <img
                          className="object-cover w-full h-full overflow-visible"
                          src={blok.image?.filename}
                          alt={""}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={mergeClasses("hidden lg:block absolute inset-0 w-[50vw]",
                                          isImageLeft ? "ml-[-50vw] mr-[100%]" : "ml-[100%]",
                                          blok.iconBackgroundColour
                                            ? "bg-" + blok.iconBackgroundColour
                                            : "bg-transparent")}/>
          </div>
        )}
      </div>
    </div>
  );
};

export default SemiCircleWithOffsetImage;
