import React, { useState } from 'react'
import Link from "next/link";
import { absoluteUrl, mergeClasses, PropertyExists } from '../lib/_helper';

export default function FloatingBannerText({ blok, shared }) {
  const hasLink = PropertyExists(blok?.link?.url);

  return (
    <p className={
      mergeClasses(
        "text-sm leading-6 mx-8",
        blok?.textColour ? ("text-" + blok.textColour) : "text-white"
      )
    }>
      <Link 
        href={hasLink ? absoluteUrl(blok?.link?.url) : ""} 
        className={
          mergeClasses(hasLink ? "hover:underline" : "pointer-events-none")
        }
      >
        <strong className="font-semibold">{blok?.primaryText}</strong>
        {PropertyExists(blok?.secondaryText) && (<span>&nbsp;-&nbsp;</span>)}
        {blok?.secondaryText}
        {blok?.includeArrow && (<>&nbsp;&nbsp;<span aria-hidden="true">&rarr;</span></>)}
      </Link>
    </p>
  )
}
