import react, { useEffect, useState } from "react";
import { useRouter } from 'next/router';
import DynamicComponent from "./DynamicComponent";

export default function Survey({ blok, shared }) {
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [currentPageChecked, setCurrentPageChecked] = useState(false);
  const [agreedToAll, setAgreedToAll] = useState(false);

  const router = useRouter();
  const { a: uid } = router.query;

  const totalPages = blok.disclaimerPages?.length ?? 0;

  const handleContinue = () => {
    if (currentPageIndex < totalPages - 1) {
      setCurrentPageIndex(currentPageIndex + 1);
    } else {
      setAgreedToAll(true);
    }
  };

  return (
    <main>
      <div className="max-w-7xl mx-auto mb-8">
        {blok.aboveSurvey?.map((blok) => (
          <DynamicComponent blok={blok} key={blok._uid} shared={shared} />
        ))}
        {(!agreedToAll && totalPages != 0) && (
          <div>
            <div key={blok.disclaimerPages[currentPageIndex]._uid}>
              {blok.disclaimerPages[currentPageIndex].bloks?.map((blok) => (
                <DynamicComponent blok={blok} key={blok._uid} shared={shared} />
              ))}
              <div className="space-y-2 p-4">
                {blok.disclaimerPages[currentPageIndex].hasCheckbox && 
                  (<label className="flex justify-center space-x-2">
                    <input
                      type="checkbox"
                      checked={currentPageChecked}
                      onChange={() => setCurrentPageChecked(!currentPageChecked)}
                      className="form-checkbox h-4 w-4 my-1"
                    />
                    <span>{blok.disclaimerPages[currentPageIndex].checkboxText ?? "I agree"}</span>
                  </label>)
                }
                <div className="flex justify-center">
                  <button
                    type="submit"
                    className="px-4 py-2 bg-cto_primary_purple text-white rounded disabled:opacity-50"
                    onClick={handleContinue}
                    disabled={blok.disclaimerPages[currentPageIndex].hasCheckbox && !currentPageChecked}
                  >
                    {blok.disclaimerPages[currentPageIndex].buttonText ?? "Continue"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {(agreedToAll || totalPages == 0)  && (
          <div>
            <iframe
              id="questionnaire-iframe"
              className="w-full min-h-[720px] h-[960px] my-10"
              src={`https://s.ctolabs.com.au/cybersecurityposture/?rid=uid_${uid}`}
            />
          </div>
        )}
      </div>
    </main>
  );
}
