import { mergeClasses, absoluteUrl } from "../lib/_helper";
import swal from "sweetalert";
import { useState } from 'react'
import { Label, Listbox, ListboxButton, ListboxOption, ListboxOptions, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/outline'

export const gicsSectors = [
  "Energy",
  "Materials",
  "Industrials",
  "Consumer Discretionary",
  "Consumer Staples",
  "Health Care",
  "Financials",
  "Information Technology",
  "Communication Services",
  "Utilities",
  "Real Estate"
];

const AppState = {
  EnterData: 'EnterData',
  SeeResults: 'SeeResults',
  SeeStats: 'SeeStats',
};

const Progress = ({state, setState}) => {
  const ProgressState = {
    Complete: 'complete',
    Current: 'current',
    Upcoming: 'upcoming',
  };

  const steps = [
    { id: "Step 1", stateRef: AppState.EnterData, name: 'Enter fields', status: (state == AppState.EnterData) ? ProgressState.Current : ProgressState.Complete },
    { id: "Step 2", stateRef: AppState.SeeResults, name: 'See Results & Make Selection', status: (state == AppState.SeeResults) ? ProgressState.Current : (state == AppState.EnterData ? ProgressState.Upcoming : ProgressState.Complete) },
    { id: "Step 3", stateRef: AppState.SeeStats, name: 'View Statistics', status: (state == AppState.SeeStats) ? ProgressState.Current : ProgressState.Upcoming },
  ]
  return (
    <nav aria-label="Progress">
      <ol role="list" className="space-y-4 md:flex md:space-x-8 md:space-y-0">
        {steps.map((step) => (
          <li key={step.name} className="md:flex-1">
            {step.status === ProgressState.Complete && (
              <a
                className="group flex flex-col cursor-pointer border-l-4 border-cto_primary_purple py-2 pl-4 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4 hover:opacity-50"
                onClick={() => setState(step.stateRef)}
              >
                <span className="text-sm font-medium text-cto_primary_purple">{step.id}</span>
                <span className="text-sm font-medium">{step.name}</span>
              </a>
            )}
            {step.status === ProgressState.Current && (
              <a
                className="flex flex-col border-l-4 cursor-default border-cto_primary_orange animate-pulse py-2 pl-4 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4 hover:opacity-50"
                aria-current="step"
              >
                <span className="text-sm font-medium text-cto_primary_purple">{step.id}</span>
                <span className="text-sm font-medium">{step.name}</span>
              </a>
            )}
            {step.status === ProgressState.Upcoming && (
              <a
                className="group flex flex-col cursor-not-allowed border-l-4 border-gray-200 py-2 pl-4 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4 hover:opacity-50"
              >
                <span className="text-sm font-medium text-gray-500 group-hover:text-gray-700">{step.id}</span>
                <span className="text-sm font-medium">{step.name}</span>
              </a>
            )}
          </li>
        ))}
      </ol>
    </nav>
  )
}

const Result = ({key, name, description, alternatives, isChecked, setChecked}) => {
  // console.log(name)
  // console.log(isChecked)
  // console.log(setChecked)
  return (
    <li 
      key={key} 
      className={
        mergeClasses(
          "overflow-hidden rounded-xl border-2",
          isChecked ? " border-cto_primary_orange/50" : "border-gray-200"
      )}
      onClick={() => {setChecked()}}
    >
      <div className={
        mergeClasses(
          "flex justify-between gap-x-4 border-b border-gray-900/5 p-6",
          isChecked ? "bg-cto_primary_orange/25" : "bg-gray-50" 
        )}>
        <div className="text-sm font-medium leading-6 text-gray-900">Project {name}</div>
        <input
          id={key}
          checked={isChecked}
          type="checkbox"
          className="h-6 w-6 rounded-full border-gray-300 border text-cto_primary_orange focus:ring-cto_primary_orange"
        />
      </div>
      <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
        <div className="x-4 py-3">
          <dd className="text-gray-700">{description}</dd>
        </div>
        {(alternatives && alternatives.length > 0) && (
          <div>
            <div className="x-4 pt-3">
              <dt className="text-gray-900 underline">International variants:</dt>
            </div>
            {alternatives.map((alt, index) => {
              return (
                <div key={index} className="flex justify-between x-4 py-3">
                  <dt className="text-gray-500">{alt.name_variation}</dt>
                  <dt className="text-gray-500">({alt.language})</dt>
                </div>
              )
            })}
          </div>
        )}
      </dl>
    </li>
  )
}

const BarChart = ({ data }) => {
  const sortedData = data.sort((a, b) => b.value - a.value);
  const maxValue = Math.max(...data.map(item => item.value));

  return (
    <div className="max-w-4xl mx-auto bg-white shadow-lg rounded-lg p-6">
      <h2 className="text-2xl font-semibold mb-4">Top 10 Leading Name-Gen Firms!</h2>
      <table className="w-full table-fixed">
        <tbody>
          {sortedData.map((item, index) => (
            <tr key={index} className="align-middle">
              <td className="w-1/4 pr-4 text-sm text-gray-600">{item.label}</td>
              <td className="w-3/4">
                <div
                  className="h-6 bg-cto_primary_orange rounded-full"
                  style={{ width: `${(item.value / maxValue) * 100}%` }}
                ></div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const NameGen = ({ blok, shared }) => {
  const defaultSectorText = "Pick Sector";

  const [appState, setAppState] = useState(AppState.EnterData)


  //Stage 1
  const [submissionInProgress, setSubmissionInProgress] = useState(false)
  const [selectedGicsSector, setSelectedGicsSector] = useState(defaultSectorText)
  const [dealCharacteristics, setDealCharacteristics] = useState("")
  const [companyCharacteristics, setCompanyCharacteristics] = useState("")
  const [firmName, setFirmName] = useState("")
  const [userName, setUserName] = useState("")

  let formComplete = true;
  formComplete &= (selectedGicsSector != defaultSectorText);
  formComplete &= (companyCharacteristics != "");
  formComplete &= (dealCharacteristics != "");
  formComplete &= (firmName != "");

  //Stage 2
  const [generatedNames, setGeneratedNames] = useState([])
  const [selectedName, setSelectedName] = useState();

  //Stage 3
  const data = [
    { label: 'Five V', value: 48 },
    { label: 'Adamantem', value: 32 },
    { label: 'Quadrant PE', value: 60 },
    { label: 'Macquarie', value: 72 },
    { label: 'EQT', value: 28 },
    { label: 'GIA', value: 12 },
    { label: 'FPE', value: 6 },

    { label: 'oOh', value: 2 },
    { label: 'AWS', value: 3 },
    { label: 'CTO Labs', value: 1 },
  ];

  const onDataSubmissionClick = () => {
    if (!formComplete) {
      swal(
        "Oops!", 
        "Please fill in all of the fields to proceed.", 
        "error"
      );
      return;
    }

    const formData = new FormData()
    formData.append("sector", selectedGicsSector)
    formData.append("companyCharacteristics", companyCharacteristics)
    formData.append("dealCharacteristics", dealCharacteristics)
    formData.append("firmName", firmName)
    formData.append("userName", userName)

    setSubmissionInProgress(true);
    fetch(absoluteUrl("/api/nom-de-guerre"), {
      body: formData,
      method: "POST",
    })
    .then(async (response) => {
      // console.dir({response})
      const jsonResponse = await response.json()
      // console.dir({jsonResponse});
      setGeneratedNames(jsonResponse.generatedNames.options)
      setAppState(AppState.SeeResults);
    })
    .catch((error) => {
      console.error({error})
      swal("Oops, something went wrong!", "If you're still interested in the content, please send an email to connect@ctolabs.com.au", "error")
    })
    .finally(() => {
      setSubmissionInProgress(false);
    })
  }

  const onNameSelectionClick = () => {
    if (!selectedName) {
      swal(
        "Oops!", 
        "Please select a name to proceed.", 
        "error"
      );
      return;
    }
    setAppState(AppState.SeeStats);

    // const formData = new FormData()
    // formData.append("sector", selectedGicsSector)
    // formData.append("dealCharacteristics", dealCharacteristics)
    // formData.append("companyCharacteristics", companyCharacteristics)
    // formData.append("firmName", firmName)

    // setSubmissionInProgress(true);
    // fetch("/api/nom-de-guerre", {
    //   body: formData,
    //   method: "POST",
    // })
    // .then(async (response) => {
    //   console.dir({response})
    //   const jsonResponse = await response.json()
    //   console.dir({jsonResponse});
    //   setGeneratedNames(jsonResponse.generatedNames.options)
    //   setAppState(AppState.SeeResults);
    // })
    // .catch((error) => {
    //   console.error({error})
    //   swal("Oops, something went wrong!", "If you're still interested in the content, please send an email to connect@ctolabs.com.au", "error")
    // })
    // .finally(() => {
    //   setSubmissionInProgress(false);
    // })
  }

  return (
    <div className="bg-cto_secondary_light_warm_grey">
      <div className="max-w-7xl mx-auto p-4">
        <div className="shadow-lg space-y-6 p-8 bg-white rounded-xl">
          <div className="pb-4">
            <Progress state={appState} setState={(val) => setAppState(val)}/>
          </div>
          {appState == AppState.EnterData && (
            <>
              <div>
                <label className="font-medium leading-6">
                  <div className="label">
                    <span className="label-text">Pick the relevant GICS Sector:</span>
                  </div>
                  <div className="mt-2">
                    <select 
                      className={mergeClasses("select select-bordered w-4/5 max-w-[240px] px-4 py-2 border-cto_secondary_dark_grey placeholder:text-gray-400 text-cto_secondary_dark_grey rounded-md focus:ring-cto_primary_purple sm:text-sm sm:leading-6",
                        submissionInProgress ? "bg-gray-100" : ""
                      )}
                      onChange={(e) => setSelectedGicsSector(e.target.value)}
                      disabled={submissionInProgress}
                      value={selectedGicsSector}
                      >
                        <option disabled>{defaultSectorText}</option>
                        {gicsSectors.map((sector) => {return (<option key={sector}>{sector}</option>)})}
                    </select>
                  </div>
                </label>
              </div>
              <div>
                <label className="block font-medium leading-6 text-cto_secondary_dark_grey">
                  <div className="label">
                    <span className="label-text">Characteristics of the company being acquired:</span>
                  </div>
                  <div className="mt-2">
                    <textarea
                      rows={3}
                      value={companyCharacteristics}
                      disabled={submissionInProgress}
                      onChange={(e) => setCompanyCharacteristics(e.target.value)}
                      className={mergeClasses("block w-4/5 max-w-[640px] rounded-md border-0 px-4 py-2 text-cto_secondary_dark_grey shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cto_primary_purple sm:text-sm sm:leading-6",
                        submissionInProgress ? "bg-gray-100" : ""
                      )}
                      />
                  </div>
                </label>
              </div>
              <div>
                <label className="block font-medium leading-6 text-cto_secondary_dark_grey">
                  <div className="label">
                    <span className="label-text">Characteristics of the transaction:</span>
                  </div>
                  <div className="mt-2">
                    <textarea
                      rows={3}
                      value={dealCharacteristics}
                      disabled={submissionInProgress}
                      onChange={(e) => setDealCharacteristics(e.target.value)}
                      className={mergeClasses("block w-4/5 max-w-[640px] rounded-md border-0 px-4 py-2 text-cto_secondary_dark_grey shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cto_primary_purple sm:text-sm sm:leading-6",
                        submissionInProgress ? "bg-gray-100" : ""
                      )}
                    />
                  </div>
                </label>
              </div>
              <div>
                <label className="block font-medium leading-6 text-gray-900">
                  <div className="flex justify-between space-x-4 w-4/5 max-w-[640px]">
                    <div className="w-[312px]">
                      <div className="label">
                        <span className="label-text">Firm Name:</span>
                      </div>
                      <div className="mt-2">
                        <input
                          value={firmName}
                          disabled={submissionInProgress}
                          onChange={(e) => setFirmName(e.target.value)}
                          className={mergeClasses("block rounded-md border-0 px-4 py-2 w-full max-w-[320px] text-cto_secondary_dark_grey shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cto_primary_purple sm:text-sm sm:leading-6",
                          submissionInProgress ? "bg-gray-100" : ""
                        )}
                        placeholder="CTO Labs PE"
                        />
                      </div>
                    </div>
                    <div className="w-[312px]">
                      <div className="label">
                        <span className="label-text">Your Name:</span><span className="label-text text-sm text-gray-500">&nbsp; (optional)</span>
                      </div>
                      <div className="mt-2">
                        <input
                          value={userName}
                          disabled={submissionInProgress}
                          onChange={(e) => setUserName(e.target.value)}
                          className={mergeClasses("block rounded-md border-0 px-4 py-2 w-full max-w-[320px] text-cto_secondary_dark_grey shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cto_primary_purple sm:text-sm sm:leading-6",
                          submissionInProgress ? "bg-gray-100" : ""
                        )}
                        placeholder="John Appleseed"
                        />
                      </div>
                    </div>
                  </div>
                  
                </label>
              </div>
              <div>
                <button 
                  className={
                    mergeClasses(
                      "inline-flex text-white font-bold max-w-[360px] w-4/5 items-center justify-center px-18 py-3 border border-transparent text-base rounded-full ",
                      formComplete
                        ? "bg-cto_primary_orange hover:bg-cto_primary_purple cursor-pointer"
                        : "bg-cto_secondary_dark_grey hover:bg-opacity-50 cursor-not-allowed",
                      submissionInProgress
                        ? "animate-pulse"
                        : ""
                    )}
                  onClick={onDataSubmissionClick}
                  disabled={submissionInProgress}
                >
                  {submissionInProgress 
                    ? (<div className="flex">
                      <svg class="animate-spin -ml-1 mr-3 h-4 w-4 mt-1 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="2"></circle>
                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      Processing...
                    </div>)
                    : (<>Generate Names</>)
                  }
                </button>
              </div>
            </>
          )}
          {appState == AppState.SeeResults && (
            <>
              <div className="p-4 lg:p-8">
                <ul role="list" className="grid grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-2 lg:grid-cols-3 xl:gap-x-8">
                  {generatedNames.map((option, index) => ( 
                    <Result key={index} name={option.name} description={option.description} alternatives={option.language_variations} isChecked={option.name == selectedName} setChecked={() => setSelectedName(option.name)}/>
                  ))}
                </ul>
              </div>
              <div>
                <button 
                  className={
                    mergeClasses(
                      "inline-flex text-white font-bold max-w-[360px] w-4/5 items-center justify-center px-18 py-3 border border-transparent text-base rounded-full ",
                      selectedName
                        ? "bg-cto_primary_orange hover:bg-cto_primary_purple cursor-pointer"
                        : "bg-cto_secondary_dark_grey hover:bg-opacity-50 cursor-not-allowed",
                    )}
                  onClick={onNameSelectionClick}
                  disabled={submissionInProgress}
                >
                  Select Name
                </button>
              </div>
            </>
          )}
          {appState == AppState.SeeStats && (
            <>
              <div className="py-10">
                <BarChart data={data} />
              </div>
              <div>
                <button 
                  className="inline-flex text-white font-bold max-w-[360px] w-4/5 items-center justify-center px-18 py-3 border border-transparent text-base rounded-full bg-cto_primary_orange hover:bg-cto_primary_purple cursor-pointer"
                  onClick={() => setAppState(AppState.EnterData)}
                >
                  Take me back to the beginning
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default NameGen;
