import swal from "sweetalert";
import React from "react"
import EmailValidator from "email-validator";
import { sbEditable } from "@storyblok/storyblok-editable";
import { PropertyExists } from "../lib/_helper";

const GatedContent = ({ blok }) => {
  const submitRequestForContent = async (event) => {
    event.preventDefault();

    const target = event?.target
    const email = target?.emailAddress.value;
  
    //belt and braces email validation check
    if (!EmailValidator.validate(email)) {
      swal("Oops!", "Invalid Email Format", "error")
      return;
    }

    const formData = new FormData()
    formData.append("firstName", target?.firstName?.value)
    formData.append("surname", target?.surname?.value)
    formData.append("email", email)
    formData.append("gatedContentID", target?.gatedContentID?.value)
    formData.append("humanReadableName", target?.humanReadableName?.value)
  
    disableFormOnSubmission()
    fetch("/api/requestGatedContent", {
      body: formData,
      method: "POST",
    }).then((response) => {
      disableFormAfterSuccessfulSubmission();
      swal({
        title: "Thanks for your interest, we've sent a download link to your email.",
        icon: "success",
        button: "close",
      });
    })
    .catch((error) => {
      console.error({error})
      reenableFormAfterFailedSubmission();
      swal("Oops, something went wrong!", "If you're still interested in the content, please send an email to connect@ctolabs.com.au", "error")
    })
  };

  const disableFormOnSubmission = () => {
    // Disable all of the inputs
    const fieldset = document.getElementById("gated-content-form-fieldset")
    fieldset.disabled = true;
    fieldset.style.opacity = .5;

    const submitButton = document.getElementById("gated-content-form-submit-button").style.display = "none";
    const processingDisplay = document.getElementById("gated-content-form-processing-display").style.display = "inline-flex";
  }

  const disableFormAfterSuccessfulSubmission = () => {
    // Replace the 'Processing' button with a disabled 'Submit' Button.
    const submitButton = document.getElementById("gated-content-form-submit-button")
    submitButton.style.display = "inline-flex";
    submitButton.style.opacity = .5;
    // Prevent the hover animation from occuring on the disabled button.
    submitButton.style.pointerEvents = "none";
    
    const processingDisplay = document.getElementById("gated-content-form-processing-display").style.display = "none";
  }

  const reenableFormAfterFailedSubmission = () => {
    // Hide the processing button
    const processingDisplay = document.getElementById("gated-content-form-processing-display").style.display = "none";

    // Re-enable all of the inputs
    const fieldset = document.getElementById("gated-content-form-fieldset")
    fieldset.disabled = false;
    fieldset.style.opacity = 1.0;

    // Show the submit button again.
    const submitButton = document.getElementById("gated-content-form-submit-button")
    submitButton.style.display = "inline-flex";
    submitButton.style.opacity = 1.0;
  }

  return (
    <div className="relative bg-transparent" {...sbEditable(blok)}>
      <div className="relative max-w-xl mx-auto">
        <div className="py-4 px-10">
          <div className="max-w-lg mx-auto lg:max-w-none">
            <form id="gated-content-form" className="font-dm-sans" onSubmit={submitRequestForContent}>
              <fieldset id="gated-content-form-fieldset" className="grid grid-cols-1 gap-y-3">
                <input type="hidden" id="gatedContentID" name="gatedContentID" value={blok.gatedContentID} />
                <input type="hidden" id="humanReadableName" name="humanReadableName" value={blok.humanReadableNameForContent} />

                <div className="w-full grid grid-cols-5">
                  <div className="col-span-2 rounded-[2rem] rounded-r-none py-3 px-6 shadow-sm ring-1 ring-inset ring-gray-300 bg-white focus-within:ring-2 focus-within:ring-indigo-600">
                    <label htmlFor="firstName" className="block text-xs font-medium text-gray-900">
                      First Name
                    </label>
                    <input
                      type="text"
                      name="firstName"
                      id="firstName"
                      className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                      placeholder="Jane"
                      />
                  </div>
                  <div className="col-span-3 rounded-[2rem] rounded-l-none py-3 px-6 shadow-sm ring-1 ring-inset ring-gray-300 bg-white focus-within:ring-2 focus-within:ring-indigo-600">
                    <label htmlFor="surname" className="block text-xs font-medium text-gray-900">
                      Last Name
                    </label>
                    <input
                      type="text"
                      name="surname"
                      id="surname"
                      className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                      placeholder="Smith"
                      />
                  </div>
                </div>

                <div className="rounded-[2rem] py-3 px-6 shadow-sm ring-1 ring-inset ring-gray-300 bg-white focus-within:ring-2 focus-within:ring-indigo-600">
                  <label htmlFor="emailAddress" className="block text-xs font-medium text-gray-900">
                    Email Address
                  </label>
                  <input
                    type="text"
                    name="emailAddress"
                    id="emailAddress"
                    className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    placeholder="example@email.com"
                    pattern="[^@\s]+@[^@\s]+"
                    required
                  />
                </div>
              </fieldset>
              <div className="w-full font-dm-sans pt-3">
                  <button
                    id="gated-content-form-submit-button"
                    type="submit"
                    className="inline-flex w-full justify-center py-3 px-6 border border-transparent shadow-sm text-base font-medium rounded-full text-white bg-cto_primary_orange hover:bg-cto_primary_purple focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cto_primary_purple transition ease-in-out duration-150"
                  >
                    {PropertyExists(blok.buttonText) ? blok.buttonText : "Send me the rest of this content."}
                  </button>
                  <button 
                    id="gated-content-form-processing-display"
                    type="button" 
                    className="hidden w-full justify-center py-3 px-6 border border-transparent shadow-sm text-base font-medium rounded-full text-white bg-cto_primary_orange hover:bg-cto_primary_purple focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cto_primary_purple transition ease-in-out duration-150 cursor-not-allowed" disabled="">
                    <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                      <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    Processing...
                  </button>
                </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GatedContent;
