import { sbEditable } from "@storyblok/storyblok-editable";
import Faq from "./Faq";

const Faqs = ({ blok }) => {
  return (
    <div className="bg-transparent" {...sbEditable(blok)}>
      <div className="max-w-7xl mx-auto py-20 px-4 sm:py-24 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto divide-y-2 divide-gray-200">
          {blok?.title?.length > 0 && (
            <h2 className="text-center text-3xl font-normal font-montserrat text-gray-900 sm:text-4xl mb-6">
              {blok.title}
            </h2>
          )}
          <dl className="space-y-6 divide-y divide-gray-200">
            {blok.faq.map((blok) => (
              <Faq blok={blok} key={blok._uid} />
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
};

export default Faqs;
