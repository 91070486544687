import { sbEditable } from "@storyblok/storyblok-editable";
import "@animxyz/core";
import { XyzTransition } from "@animxyz/react";

const HomeHero = ({ blok }) => {
  const toggled = true;

  return (
    <div className="bg-cto_secondary_navy_blue" {...sbEditable(blok)}>
      <div className="text-center h-screen flex justify-center items-center md:h-auto py-2 md:py-52 lg:py-52 max-w-3xl mx-auto px-6 lg:px-8">

        <div className="grid grid-cols-1 -mt-52 md:mt-0">
          {blok?.title?.length > 0 && (
            <XyzTransition
              appearVisible={{ threshold: 0, rootMargin: "-100px" }}
              xyz="fade left-5 duration-8 ease"
            >
              {toggled && (
                <h1
                  className="font-montserrat font-normal text-6xl py-6"
                // "sm:text-4xl"
                >
                  <span className="text-cto_secondary_light_warm_grey">
                    {blok.title}
                  </span>
                </h1>
              )}
            </XyzTransition>
          )}
          {blok?.subtitle?.length > 0 && (
            <h2
              className="font-montserrat font-normal text-3xl"
            // "sm:text-4xl"
            >
              <span className=" text-cto_primary_pink">{blok.subtitle}</span>
            </h2>
          )}
        </div>
      </div>
    </div>
  );
};

export default HomeHero;
