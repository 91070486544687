import "@animxyz/core";
import React from "react";
import { XyzTransition } from "@animxyz/react";
import { sbEditable } from "@storyblok/storyblok-editable";
import { ResolveRichText } from "../lib/storyblok";
import { mergeClasses, PropertyExists } from "../lib/_helper";

const BigTitleText = ({ blok }) => {
  return (
    <div {...sbEditable(blok)}>
      <div
        className={mergeClasses(
          blok.backgroundColour
            ? "bg-" + blok.backgroundColour
            : "bg-cto_secondary_navy_blue",
          "px-8",
          blok.verticalPadding ? ("py-" + blok.verticalPadding) : "py-0"
        )}
      >
        <div className={mergeClasses("w-full h-full my-auto flex align-middle items-center")}>
          {blok?.title?.length > 0 && (
            <XyzTransition
              appearVisible={{ threshold: 0, rootMargin: "-100px" }}
              xyz="fade left-5 duration-8 ease"
            >
              <div>
                <h1 className={mergeClasses(
                  "font-montserrat font-normal",
                  PropertyExists(blok.titleSize) ? ("text-" + blok.titleSize) : "text-5xl"
                )}>
                  <span className={mergeClasses(
                    PropertyExists(blok.titleColour) ? ("text-" + blok.titleColour) : "text-white")}>
                    {blok.title}
                  </span>
                </h1>
                <div className={mergeClasses(
                  PropertyExists(blok.gapBetweenTitleAndDescription) ? ("pb-" + blok.gapBetweenTitleAndDescription) : "pb-2")}
                />
                <h2 className="font-montserrat text-xl">
                  <span
                    className="articleCopy text-white bg-cto_secondary_light_warm_grey"
                    dangerouslySetInnerHTML={{
                      __html: ResolveRichText.render(blok?.text),
                    }}
                    />
                </h2>
              </div>
            </XyzTransition>
          )}
        </div>
      </div>
    </div>
  );
};

export default BigTitleText;
