import Link from "next/link";
import Image from "next/legacy/image";
import { sbEditable } from "@storyblok/storyblok-editable";
import { absoluteUrl, mergeClasses, PropertyExists } from "../lib/_helper";
import { ResolveRichText } from "../lib/storyblok";

const TextAndSmallImageSideBySide = ({ blok }) => {
  const isImageLeft = blok.isImageLeft ?? false;
  const isTextLight = blok.isTextLight ?? false;
  const hasImage = blok.image?.filename != null;

  return (
    <div
      className={mergeClasses(
        "my-0 py-0",
        blok.backgroundColour ? "bg-" + blok.backgroundColour : "bg-transparent"
      )}
    >
      <div className={mergeClasses("mx-auto max-w-7xl")}>
        {blok && (
          <div {...sbEditable(blok)}>
            <div className={mergeClasses("min-h-[716px] py-16",
              hasImage ? "grid grid-cols-2 lg:grid-cols-4" : "flex items-center justify-center")}
            >
              <div className={mergeClasses("flex justify-start px-6 lg:pt-0 lg:justify-center items-center",
                hasImage ? "col-span-2" : "")}
              >
                <div
                  className={mergeClasses(
                    hasImage ? (blok?.isImageLeft ? "lg:pl-0 lg:pr-24" : "lg:pl-24 lg:pr-0") : "py-16"
                  )}
                >
                  {PropertyExists(blok.title) && (
                    <h2
                      className={mergeClasses(
                        "text-3xl pb-7 font-montserrat",
                        "text-" + blok.titleTextColour ?? "text-black",
                        hasImage ? "" : "text-center"
                      )}
                    >
                      {blok.title}
                    </h2>
                  )}

                  {PropertyExists(ResolveRichText.render(blok?.bodyText)) && (
                    <span
                      className={mergeClasses(
                        "text-lg articleCopy",
                        isTextLight ? "text-white" : "text-black"
                      )}
                      dangerouslySetInnerHTML={{
                        __html: ResolveRichText.render(blok?.bodyText),
                      }}
                    />
                  )}

                  {PropertyExists(blok.linkText) && (
                    (<Link
                      href={absoluteUrl(blok.link?.cached_url ?? "#")}
                      target={blok.newWindow ? "_blank" : "_self"}
                      rel="noreferrer"
                      className={mergeClasses(
                        "text-lg pt-6 font-bold underline text-cto_primary_orange"
                      )}>

                      <br />
                      {blok.linkText}

                    </Link>)
                  )}
                </div>
              </div>
              {hasImage && <div
                className={mergeClasses(
                  "col-span-2 px-4 lg:px-0 pb-8 lg:pb-0 flex items-center justify-center order-first",
                  isImageLeft ? "lg:order-first" : "lg:order-last",
                )}
              >
                {blok.image.filename.length > 0 && (
                  <div className="flex justify-center">
                    <div className="w-[300px] h-[300px] lg:w-[403px] lg:h-[403px]">
                      <Image
                        alt={""}
                        className={mergeClasses(
                          "rounded-full object-cover bg-white"
                        )}
                        src={blok?.image?.filename}
                        width="403"
                        height="403"
                      />
                    </div>
                  </div>
                )}
              </div>}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TextAndSmallImageSideBySide;
