import { sbEditable } from "@storyblok/storyblok-editable";
import { mergeClasses } from "../lib/_helper";

const ImagePanel = ({ blok }) => {
  const imageVerticalLocationMap = {
    "top": "items-start",
    "middle": "items-center",
    "bottom": "items-end",
  }

  return (
    <div {...sbEditable(blok)} className={mergeClasses(
      "max-w-7xl h-full flex justify-center p-10",
      imageVerticalLocationMap[blok.imageVerticalLocation == "" ? "middle" : blok.imageVerticalLocation],
      )}>
        <img
          className="h-auto w-full"
          src={blok.image?.filename}
          alt={""}
        />
    </div>
  );
};

export default ImagePanel;
