import "@animxyz/core";
import { sbEditable } from "@storyblok/storyblok-editable";
import { XyzTransition } from "@animxyz/react";

import { mergeClasses } from "../lib/_helper";
import Link from 'next/link'

const CareersHero = ({ blok }) => {
  const toggled = true;

  const mediaFileName = blok?.media?.filename;
  const mediaExtension = mediaFileName.split('.').pop().toLowerCase();
  const isMediaVideo = ["mp4"].includes(mediaExtension)
  const isMediaImage = ["jpg", "jpeg", "png"].includes(mediaExtension)

  return <>
    <div
      className={mergeClasses(
        "flex mx-auto justify-center",
        blok?.backgroundColour
          ? "bg-" + blok.backgroundColour
          : "bg-cto_secondary_navy_blue"
      )}
      {...sbEditable(blok)}
    >
      <div className="relative">
        <div>
          <div className="lg:hidden h-auto overflow-hidden flex justify-center mx-[-20%] mb-16 lg:mt-16 pb-20">
            <div className={mergeClasses("relative overflow-hidden rounded-full object-cover max-w-[896px] h-[896px] mt-[-600px] w-full flex items-center justify-center",
              blok?.iconBackgroundColour
                ? "bg-" + blok.iconBackgroundColour
                : "bg-transparent"
            )}>
              {isMediaVideo && 
                <video autoPlay muted loop 
                  className="mt-[600px]"
                >
                  <source src={mediaFileName} type="video/mp4"/>
                  Your browser does not support the video tag.
                </video>
              }
              {isMediaImage &&
                <img
                  className="mt-[600px]"
                  src={mediaFileName}
                />
              }
            </div>
          </div>
          <div className="max-w-7xl mt-[-150px] lg:grid lg:grid-cols-2 overflow-hidden py-8 w-screen">
            <div className="flex items-center">
              <div className="mx-auto lg:mx-0 lg:ml-auto max-w-2xl min-w-xl px-6 lg:pl-8 lg:pr-16 h-min">
                {blok?.title?.length > 0 && (
                  <XyzTransition
                    appearVisible={{ threshold: 0, rootMargin: "-100px" }}
                    xyz="fade left-5 duration-8 ease"
                  >
                    {toggled && (
                      <h1 className="font-montserrat font-normal text-5xl py-4">
                        <span className="text-white">{blok.title}</span>
                      </h1>
                    )}
                  </XyzTransition>
                )}

                <h2 className="font-montserrat text-xl py-8 articleCopy text-white">
                  {blok?.bodyText}
                </h2>
                {blok?.urlText?.length > 0 && (
                  (<Link
                    className="font-dm-sans my-4 w-full inline-flex items-center justify-center border border-transparent rounded-full sm:w-auto py-4 font-medium text-base px-10 text-white bg-cto_primary_orange hover:text-cto_secondary_navy_blue  hover:shadow hover:shadow-black hover:bg-cto_secondary_light_warm_grey"
                    href={blok.url.url ?? '#'}
                  >

                    {blok.urlText}

                  </Link>)
                )}
              </div>
            </div>
            <div className={mergeClasses("hidden lg:block lg:col-span-1")}>
              <div className="h-[800px] w-[896px]">
                <div
                  className={mergeClasses(
                    "relative rounded-full object-cover w-[896px] h-[896px] flex justify-center items-center mt-[-110px] overflow-hidden",
                    blok?.iconBackgroundColour
                      ? "bg-" + blok.iconBackgroundColour
                      : "bg-transparent",
                    "mr-[-282px]"
                  )}
                >
                  {isMediaVideo && 
                    <video autoPlay muted loop 
                      className="mr-[282px]"
                    >
                      <source src={mediaFileName} type="video/mp4"/>
                      Your browser does not support the video tag.
                    </video>
                  }
                  {isMediaImage &&
                    <img
                      className="mr-[282px]"
                      src={mediaFileName}
                    />
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={mergeClasses("absolute inset-0 w-[50vw]",
          "ml-[99%] mt-[-150px]",
          "hidden lg:block",
          blok?.iconBackgroundColour
            ? "bg-" + blok.iconBackgroundColour
            : "bg-transparent")} />
      </div>
    </div>
  </>;
};

export default CareersHero;
