import React from "react";
import { sbEditable } from "@storyblok/storyblok-editable";
import HorizontalProfileCard from "./HorizontalProfileCard";

const FullWidthVerticleImages = ({ blok }) => {
  return (
    <div className="bg-white" {...sbEditable(blok)}>
      <div className="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24">
        <div className="space-y-12">
          <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">{blok.title}</h2>

          <ul
            role="list"
            className="space-y-12 lg:grid lg:grid-cols-2 lg:items-start lg:gap-x-8 lg:gap-y-12 lg:space-y-0"
          >
            {blok.profiles.map((profile) => (
              <HorizontalProfileCard profile={profile} key={profile._uid} />
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default FullWidthVerticleImages;
