import React from "react";
import { sbEditable } from "@storyblok/storyblok-editable";
import { submitSignUp } from "../../lib/submitNewsletterSignUp";

const SignUpEmail = ({ blok }) => {
  return (
    <div className="bg-transparent py-16 sm:py-24" {...sbEditable(blok)}>
      <div className="relative sm:py-16">
        <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
          <div className="relative md:rounded-sm rounded-xl px-6 py-10 bg-cto_secondary_navy_blue overflow-hidden shadow-xl sm:px-12 sm:py-20">
            <div className="relative">
              <div className="sm:text-center">
                <h2 className="text-3xl font-extrabold text-white tracking-tight sm:text-4xl">
                  {blok?.title ?? "{title}"}
                </h2>
                <p className="mt-6 mx-auto max-w-2xl text-lg text-gray-200">
                  {blok?.description ?? "{description}"}
                </p>
              </div>
              <form
                onSubmit={submitSignUp}
                className="mt-12 sm:mx-auto sm:max-w-lg sm:flex"
              >
                <div className="min-w-0 flex-1">
                  <label htmlFor="emailAddress" className="sr-only">
                    Email address
                  </label>
                  <input
                    id="emailAddress"
                    name="emailAddress"
                    type="email"
                    className="block w-full border border-transparent rounded-full px-5 py-3 text-base text-gray-900 placeholder-gray-500 shadow-sm focus:outline-none focus:border-transparent focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600"
                    placeholder={blok?.emailPlaceholder ?? "{emailPlaceholder}"}
                  />
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-3">
                  <button
                    type="submit"
                    className="block w-full rounded-full border border-transparent px-5 py-3 bg-indigo-500 text-base font-medium text-white shadow hover:bg-indigo-400 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600 sm:px-10"
                  >
                    {blok?.buttonText ?? "{buttonText}"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpEmail;
