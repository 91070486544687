const Loading = () => {
  return (
    <div className="h-screen bg-cto_secondary_light_warm_grey">
      <div className="h-full mt-[-100px]">
        <div className="flex justify-center space-x-2 animate-bounce h-10 w-full absolute top:50% -translate-y-1/2 top-1/2 opacity-50">
          <div className="w-8 h-8 bg-cto_primary_purple rounded-full"></div>
          <div className="w-8 h-8 bg-cto_primary_purple rounded-full"></div>
          <div className="w-8 h-8 bg-cto_primary_purple rounded-full"></div>
        </div>
      </div>
    </div>
  );
};

export default Loading;
