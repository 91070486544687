import Image from "next/legacy/image";

const ContainerMaskedImage = ({ blok }) => {
  return (
    <>
      {blok?.image?.filename && (
        <div className="relative overflow-hidden bg-no-repeat">
          <Image
            src={blok?.image?.filename}
            alt={blok?.image?.alt}
            width={550}
            height={550}
          />
          <div className="absolute top-0 right-0 bottom-0 left-0">
            <Image
              src={"/masks/" + blok.mask}
              height={550}
              width={550}
              alt=""
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ContainerMaskedImage;
