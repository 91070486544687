import React, { useState, useEffect, useCallback } from 'react'
import { sbEditable } from "@storyblok/storyblok-editable";
import ImageGallery from "react-image-gallery";

export default function GalleryComponent({ blok, shared }) {
  const [images, setImages] = useState([]); 
  const [loadedImages, setLoadedImages] = useState({});

  useEffect(() => {
    if ((blok?.images?.length ?? 0) > 1) {
      loadPhotosForGallery(blok.images)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blok.images]);

  const loadPhotosForGallery = (imgArr) => {
    imgArr.forEach(img => {
        var src = img.filename;
        if (src in loadedImages) {
          return;
        } else {
          let newDictionary = {};
          newDictionary[src] = true;
          setLoadedImages(oldLoadedImages => Object.assign({}, oldLoadedImages, newDictionary))
        }

        var mockImage = document.createElement('img');
        mockImage.src = src;
        mockImage.onload = function () {
          // let aspect = getAspectRatio(mockImage.height, mockImage.width);
          let newImage = {
            original: src,
            thumbnail: src,
          }
          setImages(oldImages => [...oldImages, newImage]);
        }
    })
  }

  if (images.length > 0) {

    return (
      <div {...sbEditable(blok)} className="max-w-7xl mx-auto w-full py-4 px-4">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {images.map((image, index) => (
            <div key={index} className="w-full">
              <ImageGallery
                items={[image]}
                showThumbnails={false}
                showFullscreenButton={false}
                showPlayButton={false}
                showNav={false}
              />
            </div>
          ))}
        </div>
      </div>
    )
  }
  
  return "No photos loaded into the Gallery...";
}
