import { sbEditable } from "@storyblok/storyblok-editable";
import Link from "next/link";
import Image from "next/legacy/image";
import { absoluteUrl } from "../../lib/_helper";
import { mergeClasses } from "../../lib/_helper";

const circle =
  "M0 0L0 850.5L850.5 850.5L850.5 0L0 0ZM425.25 113.406C597.48 113.406 737.094 246.342 737.094 410.344L737.094 440.156C737.094 604.158 597.48 737.094 425.25 737.094C253.02 737.094 113.406 604.158 113.406 440.156L113.406 410.344C113.406 246.342 253.02 113.406 425.25 113.406Z";

export default function ProfileComponent({ blok }) {
  const profile = blok.profile.content;

  return (
    <li key={profile?.id} {...sbEditable(blok)}>
      <div className="space-y-6 cursor-pointer">
        <div className="overflow-hidden bg-no-repeat relative">
          <div className="top-0 left-0">
            <div className="pt-6">
              <Image
                className="bg-cto_secondary_light_warm_grey"
                src={profile?.image?.filename || "/PortraitPlaceholder.png"}
                width={500}
                height={500}
                alt={profile?.image?.alt || profile?.name}
              />
            </div>

          </div>
          <div className="absolute top-0 left-0 h-full w-full">

            <Link href={absoluteUrl(blok?.profile?.full_slug ?? "#")} legacyBehavior>
              <svg
                className={mergeClasses(
                  blok.colour
                    ? "text-" + blok.colour
                    : "text-cto_primary_purple"
                )}
                fill="currentColor"
                viewBox="0 0 850.5 850.5"
              >
                <path d={blok.shape ?? circle} />
              </svg>
            </Link>
            <div
              className={mergeClasses(
                blok.colour ? "bg-" + blok.colour : "bg-cto_primary_purple",
                "pl-4 -mt-8"
              )}
            >
              <div
                className={mergeClasses(
                  blok.darkText
                    ? "text-cto_secondary_navy_blue"
                    : "text-white",
                  "grid grid-cols-2"
                )}
              >
                <div className="text-left py-2 w-max">
                  <p className="font-light">{profile?.name}</p>
                  <p className="font-light text-sm">{profile?.role}</p>
                </div>
                <div className="ml-auto my-auto pr-4 relative z-50">
                  <Link href={absoluteUrl(profile?.linkedin?.url)}>

                    <span className="sr-only">LinkedIn</span>
                    <svg
                      className="w-5 h-5 hover:opacity-50"
                      aria-hidden="true"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                        clipRule="evenodd"
                      />
                    </svg>

                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
}
