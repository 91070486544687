import Image from "next/legacy/image";
import { sbEditable } from "@storyblok/storyblok-editable";
import Link from "next/link";
import { absoluteUrl } from "../../lib/_helper";

const NavCard = ({ blok }) => {
  return (
    <div {...sbEditable(blok)} className="w-60">
      <div className="flex flex-col rounded-sm shadow-lg overflow-hidden bg-cto_secondary_navy_blue">
        <div className="flex-shrink-0 p-4 mx-auto">
          {blok?.image?.filename?.length > 0 && (
            <Image
              className="h-28 object-cover"
              src={blok?.image?.filename}
              alt={blok?.image?.alt}
              width={200}
              height={100}
            />
          )}
        </div>
        <div className="flex-1 bg-white px-6 flex flex-col justify-between">
          <div className="flex-1">
            <Link href={absoluteUrl(blok?.href)} className="block mt-2">

              <p className="text-lg font-semibold text-gray-900">
                {blok?.title}
              </p>
              <p className="text-sm text-gray-500">{blok?.description}</p>

            </Link>
          </div>
          <p className="text-sm py-4 font-medium text-cto_primary_orange">
            <Link href={absoluteUrl(blok?.url?.url || "#")} className="hover:underline">
              {blok?.linkText}→
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default NavCard;
