import Image from "next/legacy/image";
import { mergeClasses } from '../../lib/_helper';

const ContainerImage = ({ blok }) => {
  const imageRight = true;
  return (
    <div className="w-[550px] h-[550px] py-3 bg-stone-100 rounded-[180px]">
      {blok?.image?.filename &&
        <Image
          className={mergeClasses("w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:h-full lg:w-auto lg:max-w-none",
            imageRight ? "lg:left-0" : "lg:right-0")}
          src={blok?.image?.filename}
          alt={blok?.image?.alt}
          width={550}
          height={550}
        />
      }
    </div>
  )
}

export default ContainerImage;