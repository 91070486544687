import { ResolveRichText } from "../lib/storyblok";
import { sbEditable } from "@storyblok/storyblok-editable";
import { mergeClasses } from "../lib/_helper"

export default function CenteredCopy({ blok }) {
  return (
    <div {...sbEditable(blok)}>
      <div className={mergeClasses(
        blok.removePadding ? " -mb-2" : "pb-24"
        , "pt-24 max-w-6xl mx-auto text-4xl px-4 text-center font-light sm:px-6 lg:px-8")
      }>
        <span
          className="text-gray-600 articleCopy"
          dangerouslySetInnerHTML={{
            __html: ResolveRichText.render(blok?.text),
          }}
        />
      </div>
    </div>
  );
}
