import { sbEditable } from "@storyblok/storyblok-editable";
import "@animxyz/core";
import Image from "next/legacy/image";

const SubSectionHero = ({ blok }) => {
  return (
    <div
      className="bg-cto_secondary_navy_blue pb-12 w-screen mx-auto -my-12 mb-0"
      {...sbEditable(blok)}
    >
      <div className="grid grid-cols-2 md:grid-cols-5 bg-green pb-12 pt-28 px-6 lg:px-8 max-w-7xl mx-auto">
        <div className="max-w-2xl py-4 my-auto mx-auto col-span-3 flex justify-center">
          <h1
            className="font-montserrat font-normal text-5xl"
          >
            <span className="text-white">{blok?.title}</span>
          </h1>
        </div>
        <div className="place-content-center mr-auto col-span-2 order-first md:order-last">
          {blok?.image?.filename?.length > 0 && (
            <Image 
              src={blok?.image?.filename}
              alt=""
              height={400}
              width={400}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SubSectionHero;
