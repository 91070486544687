import { removeSuffixIfExists } from './_helper';

export const getSubdomainFromUrl = (url) => {
  const hostname = new URL(url).host;
  return getSubdomainFromHost(hostname);
}

export const getSubdomainFromHost = (hostname) => {
  const rootDomain = getRootDomainFromHost(hostname);
  if (hostname == rootDomain) {
    return null;
  }
  if (hostname.endsWith(rootDomain)) {
    const subdomain = removeSuffixIfExists(hostname, "." + rootDomain)
    if (subdomain === 'www') {
      return null;
    }
    return subdomain
  }
  throw new Error (`Hostname did not end with rootDomain, unknown circumstance, rootDomain: ${rootDomain}, hostname: ${hostname}`)
}

export const getRootDomainFromHost = (hostname) => {
  const domainParts = hostname.split('.');
  const lastPart = domainParts[domainParts.length - 1];

  // Handle localhost URLs
  if (lastPart.includes('localhost:')) {
    return lastPart;
  }

  // Check if the second-level domain is a common TLD
  const tlds = ['com', 'vercel', 'org', 'net', 'edu', 'gov'];
  const secondLevelDomain = domainParts[domainParts.length - 2];
  if (tlds.includes(secondLevelDomain)) {
    const topLevelDomain = domainParts[domainParts.length - 3];
    if (topLevelDomain) {
      return topLevelDomain + '.' + secondLevelDomain + '.' + lastPart;
    }
  }

  // Check if the last part is an IP address
  const isIpAddress = lastPart.match(/^\d{1,3}(\.\d{1,3}){3}$/);
  if (isIpAddress) {
    return hostname;
  }

  return secondLevelDomain + '.' + lastPart;
}