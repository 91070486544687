import React, { useState, useEffect } from "react";
import Image from "next/legacy/image";
import { PopupModal } from "react-calendly";
import { sbEditable } from "@storyblok/storyblok-editable";
import { HiPhoneIncoming } from "react-icons/hi";

const RequestCallback = ({ blok }) => {
   
  const effectivePhoneNumber = "+61 429 342 051" // no longer using storyblok variables, due to the fact that they were created not as content-types, and would involve changing the parameter arguments in ~80 locations...
  const effectiveEmailAddress = "connect@ctolabs.com.au" // no longer using storyblok variables, due to the fact that they were created not as content-types, and would involve changing the parameter arguments in ~80 locations...

  const [rootElement, setRootElement] = useState(null);
  const [isCalendlyOpen, setIsCalendlyOpen] = useState(false);

  useEffect(() => {
    setRootElement(document.documentElement)
  }, [])

  return (
    <div className="bg-cto_secondary_navy_blue" {...sbEditable(blok)}>
      <div className="max-w-7xl mx-auto py-6 px-6 md:py-4 md:flex md:items-center md:justify-between">
        <div className="w-[400px] h-[400px]">
          <div className="flex w-full h-full my-auto">
            <Image
              src="/CTOLabs_PatternSimple_Inverse_Cropped.svg"
              height={272}
              width={272}
              alt=""
            />
          </div>
        </div>
        <div className="flex flex-col md:flex-shrink-0 px-4 mx-auto">
          <span className="block font-normal text-cto_primary_orange text-5xl">
            {blok?.title ?? "Call today,"}
          </span>
          <span className="block font-normal text-white text-5xl">
            {blok?.subtitle ?? "or we can call you."}
          </span>
          <a href={"tel:" + effectivePhoneNumber} className="block text-white text-2xl py-6">
            {effectivePhoneNumber}
          </a>
          <a href={"mailto:" + effectiveEmailAddress} className="block text-royal-blue-200 text-xl pb-8">
            {effectiveEmailAddress}
          </a>
          <span
            onClick={() => setIsCalendlyOpen(true)}
            className="inline-flex bg-cto_primary_orange max-w-[380px] md:max-w-none cursor-pointer items-center justify-center px-18 py-3 border border-transparent text-base font-medium rounded-full text-gray-700 hover:bg-cto_primary_purple"
          >
            <HiPhoneIncoming className="text-white" />
            <span className="pl-4 text-white bg-invisible">Request callback</span>
          </span>
          <PopupModal
            url={"https://calendly.com/ctolabs-connect"}
            open={isCalendlyOpen}
            onModalClose={() => setIsCalendlyOpen(false)}
            /*
             * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
             * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
             */
            rootElement={rootElement}
          />
        </div>
      </div>
    </div>
  );
};

export default RequestCallback;
