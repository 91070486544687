import Link from "next/link";
import { HeroIcon } from "../lib/HeroIcon";
import { sbEditable } from "@storyblok/storyblok-editable";
import { absoluteUrl, mergeClasses, PropertyExists } from "../lib/_helper";
import { ResolveRichText } from "../lib/storyblok";

const CircleIconWithText = ({ blok }) => {
  const isImageLeft = blok.isImageLeft ?? false;
  const isTextLight = blok.isTextLight ?? false;

  return (
    <div
      className={mergeClasses(
        "my-0 py-0",
        blok.backgroundColour ? "bg-" + blok.backgroundColour : "bg-transparent"
      )}
    >
      <div className={mergeClasses("mx-auto max-w-7xl")}>
        {blok && (
          <div className="relative">
            <div
              className={mergeClasses(
                "overflow-hidden pb-16 lg:pb-0",
                blok.overflowTop && blok.roundedTop ? "mt-0 lg:-mt-10" : "",
                blok.overflowBottom && blok.roundedBottom ? "mb-0 lg:-mb-10" : ""
              )}
              {...sbEditable(blok)}
            >
              <div className="lg:hidden h-auto overflow-hidden flex justify-center mx-[-20%]">
                <div className={mergeClasses("rounded-full object-cover max-w-[896px] h-[896px] mt-[-600px] w-full flex items-center justify-center",
                                              blok.iconBackgroundColour
                                                ? "bg-" + blok.iconBackgroundColour
                                                : "bg-transparent"
                                )}>
                  <div className="mt-[600px]">
                    <img
                      className="h-[180px] w-auto"
                      src={blok.image?.filename}
                      alt={""}
                    />
                  </div>
                </div>
              </div>
              <div
                className={mergeClasses(
                  "lg:grid lg:grid-cols-4",
                  blok.roundedBottom ? "" : "lg:h-[800px]",
                  blok.roundedTop ? "" : "lg:mt-[-96px]"
                )}
              >
                <div className="flex justify-start px-6 lg:px-0 pt-12 lg:pt-0 lg:justify-center items-center lg:col-span-2 z-10">
                  <div className="w-[455px]">
                    <div>
                      {PropertyExists(blok.title) && (
                        <h2
                          className={mergeClasses(
                            "pb-7 font-montserrat",
                            blok.titleSize ? "text-" + blok.titleSize : "text-3xl",
                            blok.titleColour ? "text-" + blok.titleColour : "text-royal-blue-900",
                          )}
                        >
                          {blok.title}
                        </h2>
                      )}

                      {ResolveRichText.render(PropertyExists(blok?.bodyText) ? blok.bodyText : { content: [] }).length > 0 && (
                        <span
                          className={mergeClasses(
                            "text-lg articleCopy",
                            isTextLight
                              ? "text-royal-blue-200"
                              : "text-royal-blue-800"
                          )}
                          dangerouslySetInnerHTML={{
                            __html: ResolveRichText.render(blok?.bodyText),
                          }}
                        />
                      )}

                      {PropertyExists(blok.subSections) && 
                        <div className="grid grid-cols-1 md:grid-cols-2 mt-5 gap-x-16">
                          {blok.subSections.map((subSection, i) => (
                            <div key={i}>
                              <Link
                                href={absoluteUrl(subSection.link?.story?.url || "#")}
                                className="py-3 flex rounded-md text-base font-medium hover:opacity-60"
                                onClick={() => close()}>

                                {PropertyExists(subSection?.icon) &&
                                  <span className="flex-shrink-0 h-6 w-6" aria-hidden="true" >
                                    <HeroIcon icon={subSection.icon} color={"text-peachy-orange-900"} size={6} outline />
                                  </span>
                                }
                                <div className="flex-col ml-4">
                                  <div className={mergeClasses("pb-1", isTextLight ? "text-white" : "text-royal-blue-900")}>{subSection.title}</div>
                                  <div className={mergeClasses("text-sm", isTextLight ? "text-royal-blue-200" : "text-royal-blue-800")}>{subSection.description}</div>
                                </div>

                              </Link>
                            </div>
                          ))}
                        </div>
                      }

                      {PropertyExists(blok.linkText) &&
                        <Link
                          href={absoluteUrl(blok.link?.story?.url ?? '#')}
                          className="text-lg pt-6 font-bold underline text-peachy-orange-900 bg-invisible">

                          <br />
                          {blok.linkText}

                        </Link>
                      }
                    </div>
                  </div>
                </div>
                <div
                  className={mergeClasses(
                    "hidden lg:block lg:col-span-2",
                    isImageLeft ? "order-first" : "order-last"
                  )}
                >
                  <div className="h-[896px] w-[896px]">
                    <div
                      className={mergeClasses(
                        "object-cover w-[896px] h-[896px] flex pl-[145px] pr-[35px]",
                        blok.iconBackgroundColour
                          ? "bg-" + blok.iconBackgroundColour
                          : "bg-transparent",
                        isImageLeft ? "ml-[-340px] justify-end rounded-r-full" : "mr-[-340px] rounded-l-full"
                      )}
                    >
                      <img
                        className="w-[355px] h-auto"
                        src={blok.image?.filename}
                        alt={""}
                        height={328}
                        width={355}
                      />

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={mergeClasses("absolute inset-0 w-[50vw]",
                                          isImageLeft ? "ml-[-50vw] mr-[100%]" : "ml-[100%]",
                                          blok.iconBackgroundColour
                                            ? "bg-" + blok.iconBackgroundColour
                                            : "bg-transparent",)}/>
          </div>
        )}
      </div>
    </div>
  );
};

export default CircleIconWithText;
