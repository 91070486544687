import swal from "sweetalert";
import React, {useState} from "react"
import EmailValidator from "email-validator";
import { sbEditable } from "@storyblok/storyblok-editable";
import { CheckCircleIcon } from '@heroicons/react/solid';
import { absoluteUrl, mergeClasses } from "../lib/_helper";

const ContactMini = ({ blok }) => {
  const [submitted, setSubmitted] = useState(false);

  const submitContact = async (event) => {
    event.preventDefault();
    if (submitted) {
      return;
    }

    const target = event?.target
    const email = target?.emailAddress.value;
    const message = target?.message?.value + " - " + window.location.href
  
    // belt and braces email validation check
    if (!EmailValidator.validate(email)) {
      console.warn("Invalid email submitted");
      return;
    }

    const formData = new FormData()
    formData.append("firstName", target?.firstName?.value)
    formData.append("surname", target?.surname?.value)
    formData.append("email", email)
    formData.append("linkedIn", target?.linkedIn?.value)
    formData.append("phoneNumber", target?.phoneNumber?.value)
    formData.append("message", message)
    formData.append("hubspotId", target?.hubspotId?.value)
    formData.append("formType", target?.formType?.value)

    await fetch(absoluteUrl("/api/contact"), {
      body: formData,
      method: "POST",
    });
  
    swal({
      title: "Thanks for your interest, we'll be in touch very soon.",
      icon: "success",
      button: "close",
    });
    setSubmitted(true);
  };

  return (
    <div className="relative bg-transparent" {...sbEditable(blok)}>
      <div className="relative max-w-7xl mx-auto">
        <div className="py-4 px-10">
          <div className="max-w-lg mx-auto lg:max-w-none">
            <form id="contact-form" className="grid grid-cols-1 gap-y-3 font-dm-sans" onSubmit={submitContact}>
              <input type="hidden" id="hubspotId" name="hubspotId" value={blok.hubspotID} />
              <input type="hidden" id="formType" name="formType" value={blok.formType} />

              <div className="w-full grid grid-cols-5">
                <div className="col-span-2 rounded-[2rem] rounded-r-none py-3 px-6 shadow-sm ring-1 ring-inset ring-gray-300 bg-white focus-within:ring-2 focus-within:ring-indigo-600">
                  <label htmlFor="firstName" className="block text-xs font-medium text-gray-900">
                    First Name
                  </label>
                  <input
                    type="text"
                    name="firstName"
                    id="firstName"
                    className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    placeholder="Jane"
                    />
                </div>
                <div className="col-span-3 rounded-[2rem] rounded-l-none py-3 px-6 shadow-sm ring-1 ring-inset ring-gray-300 bg-white focus-within:ring-2 focus-within:ring-indigo-600">
                  <label htmlFor="surname" className="block text-xs font-medium text-gray-900">
                    Last Name
                  </label>
                  <input
                    type="text"
                    name="surname"
                    id="surname"
                    className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    placeholder="Smith"
                    />
                </div>
              </div>

              <div className="rounded-[2rem] py-3 px-6 shadow-sm ring-1 ring-inset ring-gray-300 bg-white focus-within:ring-2 focus-within:ring-indigo-600">
                <label htmlFor="emailAddress" className="block text-xs font-medium text-gray-900">
                  Email Address
                </label>
                <input
                  type="text"
                  name="emailAddress"
                  id="emailAddress"
                  className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                  placeholder="example@email.com"
                />
              </div>
            
              <div className="rounded-[2rem] py-3 px-6 shadow-sm ring-1 ring-inset ring-gray-300 bg-white focus-within:ring-2 focus-within:ring-indigo-600">
                <label htmlFor="phoneNumber" className="block text-xs font-medium text-gray-900">
                  Phone
                </label>
                <input
                  type="text"
                  name="phoneNumber"
                  id="phoneNumber"
                  className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                  placeholder="04########"
                />
              </div>

              <div className="rounded-[2rem] py-3 px-6 shadow-sm ring-1 ring-inset ring-gray-300 bg-white focus-within:ring-2 focus-within:ring-indigo-600">
                <label htmlFor="message" className="block text-xs font-medium text-gray-900">
                  Message
                </label>
                <textarea
                  type="text"
                  name="message"
                  rows={2}
                  id="message"
                  className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 max-h-[200px] min-h-[30px] bg-transparent"
                  placeholder="Hi..."
                  defaultValue="I am interested in finding out more about..."
                />
              </div>

              
              
              <div className="w-full flex justify-end">
                <button
                  type="submit"
                  className={mergeClasses("inline-flex justify-center py-3 px-6 border border-transparent shadow-sm text-base font-medium rounded-full text-white  focus:outline-none",
                  submitted ? "bg-cto_secondary_dark_grey hover:bg-cto_secondary_dark_grey cursor-default" : "bg-cto_primary_orange hover:bg-cto_primary_purple focus-within:ring-2 focus:ring-indigo-500")}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactMini;
