import Image from "next/legacy/image";
import { DCurve } from "../masks/D-curve-mask";

const MaskedCurve = ({ blok }) => {
  return (
    <>
      {blok?.image?.filename &&
        <div className="relative overflow-hidden bg-no-repeat">
          <Image
            src={blok?.image?.filename}
            alt={blok?.image?.alt}
            width={550}
            height={550}
          />
          <div className="absolute top-0 right-0 bottom-0 left-0">
            <DCurve colour="#F32E32" />
          </div>
        </div>
      }
    </>
  )
}

export default MaskedCurve;