import swal from "sweetalert";
import React, { Fragment, useState } from "react"
import EmailValidator from "email-validator";
import { sbEditable } from "@storyblok/storyblok-editable";
import { PropertyExists } from "../lib/_helper";
import { v4 as guid } from "uuid";
import { Dialog, Transition } from '@headlessui/react'
import { HeroIcon } from '../lib/HeroIcon';

const RegisterForEvent = ({ blok }) => {
  const [open, setOpen] = useState(false)
  const uniqueFormId = guid();

  const submit = async (event) => {
    event.preventDefault();
    const target = event?.target
    const email = target?.emailAddress.value;
  
    //belt and braces email validation check
    if (!EmailValidator.validate(email)) {
      swal("Oops!", "Invalid Email Format", "error")
      return;
    }

    const formData = new FormData()
    /* User Details */
    formData.append("firstName", target?.firstName?.value)
    formData.append("surname", target?.surname?.value)
    formData.append("email", email)
    formData.append("phoneNumber", target?.phoneNumber?.value)

    /* Event Details */
    formData.append("event", blok?.event)
    formData.append("isEOI", blok?.isEOI)
    formData.append("description", blok?.description)
    formData.append("when", blok?.when)
    formData.append("where", blok?.where)
    formData.append("how", blok?.how)
  
    disableFormOnSubmission()
    fetch("/api/registerForEvent", {
      body: formData,
      method: "POST",
    }).then((response) => {
      disableFormAfterSuccessfulSubmission();
      swal({
        title: blok?.isEOI ? "Thanks for your interest, we'll let you know more about the event soon." : "Thanks for registering for the event, we have sent you details via email.",
        icon: "success",
        button: "close",
      });
    })
    .catch((error) => {
      console.error({error})
      reenableFormAfterFailedSubmission();
      swal("Oops, something went wrong!", "If you're still interested, please send an email to connect@ctolabs.com.au", "error")
    })
  };

  const disableFormOnSubmission = () => {
    // Disable all of the inputs
    const fieldset = document.getElementById(`${uniqueFormId}-fieldset`)
    fieldset.disabled = true;
    fieldset.style.opacity = .5;

    const submitButton = document.getElementById(`${uniqueFormId}-submit-button`).style.display = "none";
    const processingDisplay = document.getElementById(`${uniqueFormId}-processing-display`).style.display = "inline-flex";
  }

  const disableFormAfterSuccessfulSubmission = () => {
    // Replace the 'Processing' button with a disabled 'Submit' Button.
    const submitButton = document.getElementById(`${uniqueFormId}-submit-button`)
    submitButton.style.display = "inline-flex";
    submitButton.style.opacity = .5;
    // Prevent the hover animation from occuring on the disabled button.
    submitButton.style.pointerEvents = "none";

    const openFormButton = document.getElementById(`${uniqueFormId}-open-form`)
    openFormButton.disabled = true;
    openFormButton.style.display = "inline-flex";
    openFormButton.style.opacity = .5;
    openFormButton.style.pointerEvents = "none";
    
    const processingDisplay = document.getElementById(`${uniqueFormId}-processing-display`).style.display = "none";
  }

  const reenableFormAfterFailedSubmission = () => {
    // Hide the processing button
    const processingDisplay = document.getElementById(`${uniqueFormId}-processing-display`).style.display = "none";

    // Re-enable all of the inputs
    const fieldset = document.getElementById(`${uniqueFormId}-fieldset`)
    fieldset.disabled = false;
    fieldset.style.opacity = 1.0;

    // Show the submit button again.
    const submitButton = document.getElementById(`${uniqueFormId}-submit-button`)
    submitButton.style.display = "inline-flex";
    submitButton.style.opacity = 1.0;
  }

  return (
    <div className="relative bg-transparent" {...sbEditable(blok)}>
      <div className="relative max-w-xl mx-auto">
        <div className="py-4 px-10">
        <button
          id={`${uniqueFormId}-open-form`}
          onClick={() => setOpen(true)}
          className="inline-flex w-full justify-center py-3 px-6 border border-transparent shadow-sm text-base font-medium rounded-full text-white bg-cto_primary_orange hover:bg-cto_primary_purple focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cto_primary_purple transition ease-in-out duration-150"
        >
          {PropertyExists(blok.pageButtonText) ? blok.pageButtonText : (blok?.isEOI ? `Express your interest to '${blok?.event}'.` : `Register for '${blok?.event}'.`)}
        </button>
          <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={setOpen}>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  >
                    <Dialog.Panel className="relative transform overflow-hidden rounded-3xl bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                      <div className="absolute z-20 right-0 top-0 pr-4 pt-4">
                        <button
                          type="button"
                          className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                          onClick={() => setOpen(false)}
                        >
                          <span className="sr-only">Close</span>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6"><path fill-rule="evenodd" d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z" clip-rule="evenodd" /></svg>
                        </button>
                      </div>
                      <div className="sm:flex sm:items-start">
                        <div className="mt-3 text-center sm:mt-0 sm:text-left">
                          <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900 p-2 pt-0">
                            {blok.isEOI ? `Express Interest for the event ${blok.event}` : `Register for the event: ${blok.event}`}
                          </Dialog.Title>
                          <div className="max-w-lg mx-auto lg:max-w-none">
                            <form id={uniqueFormId} className="font-dm-sans" onSubmit={submit}>
                              <fieldset id={`${uniqueFormId}-fieldset`} className="grid grid-cols-1 gap-y-3">
                                <input type="hidden" id="gatedContentID" name="gatedContentID" value={blok.gatedContentID} />
                                <input type="hidden" id="humanReadableName" name="humanReadableName" value={blok.humanReadableNameForContent} />

                                <div className="w-full grid grid-cols-5">
                                  <div className="col-span-2 rounded-[2rem] rounded-r-none py-3 px-6 shadow-sm ring-1 ring-inset ring-gray-300 bg-white focus-within:ring-2 focus-within:ring-indigo-600">
                                    <label htmlFor="firstName" className="block text-xs font-medium text-gray-900 text-left">
                                      First Name
                                    </label>
                                    <input
                                      type="text"
                                      name="firstName"
                                      id="firstName"
                                      className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                      placeholder="Jane"
                                      />
                                  </div>
                                  <div className="col-span-3 rounded-[2rem] rounded-l-none py-3 px-6 shadow-sm ring-1 ring-inset ring-gray-300 bg-white focus-within:ring-2 focus-within:ring-indigo-600">
                                    <label htmlFor="surname" className="block text-xs font-medium text-gray-900 text-left">
                                      Last Name
                                    </label>
                                    <input
                                      type="text"
                                      name="surname"
                                      id="surname"
                                      className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                      placeholder="Smith"
                                      />
                                  </div>
                                </div>

                                <div className="rounded-[2rem] py-3 px-6 shadow-sm ring-1 ring-inset ring-gray-300 bg-white focus-within:ring-2 focus-within:ring-indigo-600">
                                  <label htmlFor="emailAddress" className="block text-xs font-medium text-gray-900 text-left">
                                    Email Address
                                  </label>
                                  <input
                                    type="text"
                                    name="emailAddress"
                                    id="emailAddress"
                                    className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                    placeholder="example@email.com"
                                    pattern="[^@\s]+@[^@\s]+"
                                    required
                                  />
                                </div>

                                <div className="rounded-[2rem] py-3 px-6 shadow-sm ring-1 ring-inset ring-gray-300 bg-white focus-within:ring-2 focus-within:ring-indigo-600">
                                  <label htmlFor="phoneNumber" className="block text-xs font-medium text-gray-900 text-left">
                                    Phone Number
                                  </label>
                                  <input
                                    type="text"
                                    name="phoneNumber"
                                    id="phoneNumber"
                                    className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                    placeholder="0412 345 678"
                                  />
                                </div>
                              </fieldset>
                              <div className="w-full font-dm-sans pt-3">
                                  <button
                                    id={`${uniqueFormId}-submit-button`}
                                    type="submit"
                                    className="inline-flex w-full justify-center py-3 px-6 border border-transparent shadow-sm text-base font-medium rounded-full text-white bg-cto_primary_orange hover:bg-cto_primary_purple focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cto_primary_purple transition ease-in-out duration-150"
                                  >
                                    {PropertyExists(blok.formButtonText) ? blok.formButtonText : "Submit request"}
                                  </button>
                                  <button 
                                    id={`${uniqueFormId}-processing-display`}
                                    type="button" 
                                    className="hidden w-full justify-center py-3 px-6 border border-transparent shadow-sm text-base font-medium rounded-full text-white bg-cto_primary_orange hover:bg-cto_primary_purple focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cto_primary_purple transition ease-in-out duration-150 cursor-not-allowed" disabled="">
                                    <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                      <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                    Processing...
                                  </button>
                                </div>
                            </form>
                          </div>
                        </div>
                      </div>
                      
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
        </div>
      </div>
    </div>
  );
};

export default RegisterForEvent;
