import Image from "next/legacy/image";
import Link from "next/link";
import { absoluteUrl } from "../lib/_helper";

const Author = ({ author, publishedDate, readingTime }) => {

  //The format that the date comes through upsets some browsers - namely Safari, Stupid safari
  const publishedDateNew = publishedDate ? new Date(publishedDate.replace(" ", "T")).toDateString() : null

  return (
    <div className="bg-transparent mt-8 mb-12 flex border-b border-t border-gray-200 max-w-max">
      <div className="flex shrink items-center">
        <div className="flex-none">
          <Link
            href={absoluteUrl(author?.full_slug ?? "/contact")}
            className="align-middle">

            <span className="sr-only">{author?.name || "author name"}</span>
            <div style={{ width: 66, height: 66, position: "relative" }}>
              <Image
                alt={author?.name + "profile picture"}
                src={
                  author?.content?.image?.filename ||
                  "/cto-dark.svg"
                }
                layout="fill"
                objectFit="contain"
              />
            </div>

          </Link>
        </div>
        <div className="ml-3">
          <p className="text-sm pb-2 font-medium text-gray-900">
            {(author?.name && author?.full_slug) &&
              <Link
                href={absoluteUrl(author?.full_slug ?? "/contact")}
                className="hover:underline">
                {author?.name}
              </Link>
            }
          </p>
          <div className="flex space-x-1 -mt-2 text-sm text-gray-500">
            {publishedDateNew && 
              <span>
                {publishedDateNew}
              </span>
            }
            {readingTime.length > 0 &&
              <>
                <span aria-hidden="true">&middot;</span>
                <span>{readingTime} min read</span>
              </>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default Author;
