import React from "react";
import { sbEditable } from "@storyblok/storyblok-editable";
import { mergeClasses } from "../lib/_helper";
import "@animxyz/core";
import { XyzTransition } from "@animxyz/react";
import NextImage from "./CustomImage";

const LogoStrip = ({ blok }) => {
  return (
    <div
      className={mergeClasses(
        blok.backgroundColour ? "bg-" + blok.backgroundColour : "bg-gray-400"
      )}
      {...sbEditable(blok)}
    >
      <div className="max-w-7xl mx-auto p-4 sm:px-6 lg:px-8">
        <XyzTransition
          appearVisible={{ threshold: 0, rootMargin: "-100px" }}
          xyz="fade down-5 duration-8 ease"
        >
          <div className="grid sm:flex sm:flex-wrap space-x-9 sm:grid-cols-2 gap-8 md:grid-cols-3 lg:grid-cols-6 place-items-center place-content-center">
            {blok.logos.map((logo, index) => (
              <div
                key={index}
                className={
                  mergeClasses(
                    "relative h-[140px]",
                    blok.wideLogoMode ? "w-[280px]" : "w-[140px]"
                )}
              >
                <NextImage
                  draggable={false}
                  src={logo.filename}
                  alt={logo.alt}
                  fill={true}
                  className="object-contain"
                />
              </div>
            ))}
          </div>
        </XyzTransition>
      </div>
    </div>
  );
};

export default LogoStrip;
