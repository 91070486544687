import Image from "next/legacy/image";
import Link from "next/link";
import { sbEditable } from "@storyblok/storyblok-editable";
import { HeroIcon } from "../lib/HeroIcon";
import { absoluteUrl } from "../lib/_helper";

const ServiceCard = ({ blok }) => {
  return (
    <div {...sbEditable(blok)} className="border border-grey-600">
      <div className="flex flex-col rounded-sm overflow-hidden h-fit md:h-full md:min-h-[400px]">
        <div className="flex-1 px-6 py-4 flex flex-col justify-between h-full">
          <div className="flex-shrink-0 px-8 pt-8">
            {blok?.service?.content?.icon.length > 0 && (
              <HeroIcon
                icon={blok?.service?.content?.icon}
                color="text-cto_primary_orange"
                size={12}
                outline
              />
            )}
          </div>
          <div className="px-8 pt-4">
            <span className="font-montserrat font-bold text-xl text-cto_secondary_navy_blue">
              {blok?.service?.content?.title}
            </span>
          </div>
          <div className="flex-1 pt-4 px-8">
            <span className="text-gray-500">
              {blok?.service?.content?.bodyText}
            </span>
          </div>
          {blok?.service?.content?.url?.cached_url?.length > 0 && (
            <div className="px-8 flex items-center py-4">
              <Link href={absoluteUrl(blok?.service?.content?.url?.cached_url)}>

                <span className="underline text-cto_primary_orange font-dm-sans text-lg font-bold bg-invisible">
                  {blok?.service?.content?.urlText}
                </span>

              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ServiceCard;
