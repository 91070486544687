import { sbEditable } from "@storyblok/storyblok-editable";
import ArticleCard from "./ArticleCard";
import { mergeClasses } from "../../lib/_helper";
import Link from "next/link";
import { absoluteUrl } from '../../lib/_helper';

export default function ColumnCards({ blok }) {
  return (
    <div
      className={mergeClasses(
        blok.backgroundColour
          ? "bg-" + blok.backgroundColour
          : "bg-transparent",
        "relative pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8"
      )}
      {...sbEditable(blok)}
    >
      {/* <div className="absolute inset-0">
        <div className="bg-white h-1/3 sm:h-2/3" />
      </div> */}
      <div className="relative max-w-7xl mx-auto">
        <div className={mergeClasses(blok.leftAlignTitle || "text-center")}>
          <h2 className="text-3xl font-montserrat tracking-tight text-gray-900 sm:text-4xl">
            {blok.title}
          </h2>
          {blok?.subtitle?.length > 0 && (
            <p className="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
              {blok.subtitle}
            </p>
          )}
          {blok?.url?.story?.url?.length > 0 && (
            <div className="text-right text-cto_primary_orange underline bg-invisible">
              <Link href={absoluteUrl(blok?.url?.story?.url)}  passHref>
                {blok.linkText}&nbsp;→
              </Link>
            </div>
          )}
        </div>
        <div
          className={mergeClasses(
            blok?.cards?.length > 0
              ? "lg:grid-cols-" + blok?.cards?.length
              : "lg:grid-cols-1",
            "mt-6 max-w-lg mx-auto grid gap-6 lg:max-w-none"
          )}
        >
          {blok.cards.map((card, index) => (
            <ArticleCard blok={card} key={index} useDefaultImage={blok.useDefaultImage} />
          ))}
        </div>
      </div>
    </div>
  );
}
