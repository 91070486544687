import React from 'react';
import { sbEditable } from '@storyblok/storyblok-editable';

const VideoEmbed = ({ blok }) => {
  const videoId = blok.videoId || "default_video_id";

  return (
    <div className="column feature max-w-7xl mx-auto my-10" {...sbEditable(blok)}>
      <div className="relative max-w-full pb-[56.25%] h-[56.25%]">
        <iframe
          className="absolute top-0 left-0 w-full h-full"
          src={`https://www.youtube.com/embed/${videoId}`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          ></iframe>
      </div>
    </div>
  );
};

export default VideoEmbed;
