import React from "react";
import Image from "next/legacy/image";
import { sbEditable } from "@storyblok/storyblok-editable";

const HorizontalProfileCard = (blok) => {

  const profile = blok.profile.profile.content;

  return (
    <li key={profile.name} {...sbEditable(profile)}>
      <div className="space-y-4 sm:grid sm:grid-cols-3 sm:gap-6 sm:space-y-0 lg:gap-8">
        <div className="h-0 aspect-w-3 aspect-h-2 sm:aspect-w-3 sm:aspect-h-4">
          <Image
            className="object-cover shadow-lg rounded-lg"
            src="https://www.fillmurray.com/200/300"
            alt={blok?.image?.alt}
            width={400}
            height={200} />
        </div>
        <div className="sm:col-span-2">
          <div className="space-y-4">
            <div className="text-lg leading-6 font-medium space-y-1">
              <h3>{profile.name}</h3>
              <p className="text-indigo-600">{profile.role}</p>
            </div>
            <div className="text-lg">
              <p className="text-gray-500">{profile.bio}</p>
            </div>
          </div>
        </div>
      </div>
    </li>
  )
}

export default HorizontalProfileCard;