import React from "react"
import { sbEditable } from "@storyblok/storyblok-editable";
import { Link } from 'react-scroll'
import { PropertyExists } from "../lib/_helper";

const GatedContent = ({ blok }) => {
  return (
    <div className="relative bg-transparent" {...sbEditable(blok)}>
      <div className="relative max-w-xl mx-auto">
        <div className="py-4 px-10">
          <div className="max-w-lg mx-auto">
            <div className="w-full">
              <Link
                className="font-dm-sans inline-flex w-full justify-center py-3 px-6 border border-transparent shadow-sm text-base font-medium rounded-full text-white bg-cto_primary_orange hover:bg-cto_primary_purple focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                to={"gated-content-form"}
                onClick={() => setTimeout(() => document.getElementById("firstName").focus(), 500)}
                spy={true}
                smooth={true}
                duration={500}
                offset={-250}
              >
                {PropertyExists(blok.buttonText) ? blok.buttonText : "Send me the rest of this content."}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GatedContent;
