import { sbEditable } from "@storyblok/storyblok-editable";
import { ResolveRichText } from "../../lib/storyblok";
import { mergeClasses } from "../../lib/_helper";

const ArticleContent = ({ blok }) => {
  return (
    <div className="bg-transparent" {...sbEditable(blok)}>
      <div className="py-8 max-w-3xl px-4 mx-auto sm:px-6 lg:px-8">
        <span className={mergeClasses(
          blok.bodyTextColour ? "text-" + blok.bodyTextColour : "text-cto_secondary_navy_blue",
          blok.bodyTextWeight ? "font-" + blok.bodyTextWeight : "font-normal",
          blok.bodyTextSize ? "text-" + blok.bodyTextSize : "text-base",
          "mt-4 leading-6 font-dm-sans")}>
          <div className="articleCopy" dangerouslySetInnerHTML={{ __html: ResolveRichText.render(blok.bodyText) }} />
        </span>
      </div>
    </div >
  )
}

export default ArticleContent;