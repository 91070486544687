import React from "react";
import { ResolveRichText } from "../../lib/storyblok";
import Image from "next/legacy/image";
import Link from "next/link";
import { sbEditable } from "@storyblok/storyblok-editable";

const Profile = ({ blok }) => {
  return (
    <div
      className="relative bg-transparent overflow-hidden"
      {...sbEditable(blok)}
    >
      <div className="max-w-7xl mx-auto">
        <div className="lg:flex  px-4 sm:px-6 lg:px-8">
          <div className="max-w-xl text-lg ml-auto mx-auto bg-cto_secondary_light_warm_grey">
            <Image
              className="w-full rounded-sm bg-cto_secondary_light_warm_grey h-auto"
              src={blok?.fullPhoto?.filename || "/PortraitPlaceholderFullSize.png"}
              width={800}
              height={1200}
              alt={blok?.headshot?.alt}
            />
          </div>
          <div className="text-gray-500 mr-auto border-t-grey-200 p-16">
            <span>TEAM</span>
            <h1>
              <span className="font-montserrat block text-4xl leading-8 font-extrabold tracking-tight text-cto_primary_purple py-8">
                {blok?.name}
              </span>
              <span className="pb-4 block text-1xl leading-8 font-bold tracking-tight text-gray-900 sm:text-2xl">
                {blok?.role}
                <Link href={blok?.linkedin?.url} className="text-gray-400 hover:text-gray-500">

                  <span className="sr-only">LinkedIn</span>
                  <svg
                    className="w-8 h-8"
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                      clipRule="evenodd"
                    />
                  </svg>

                </Link>
              </span>
            </h1>

            <div
              className="prose prose-indigo prose-lg"
              dangerouslySetInnerHTML={{
                __html: ResolveRichText.render(blok?.bio),
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
