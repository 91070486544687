import Link from "next/link";
import { sbEditable } from "@storyblok/storyblok-editable";
import { absoluteUrl, mergeClasses, PropertyExists } from "../lib/_helper";
import { ResolveRichText } from "../lib/storyblok";

const CircleImageWithText = ({ blok }) => {
  const isImageLeft = blok.isImageLeft ?? false;
  const isTextLight = blok.isTextLight ?? false;

  return (
    <div
      className={mergeClasses(
        "my-0 py-0",
        blok.backgroundColour ? "bg-" + blok.backgroundColour : "bg-transparent"
      )}
    >
      <div className={mergeClasses("mx-auto max-w-7xl")}>
        {blok && (
          <div
            className={mergeClasses(
              "overflow-hidden pb-16 lg:pb-0",
              blok.overflowTop && blok.roundedTop ? "mt-0 lg:-mt-10" : "",
              blok.overflowBottom && blok.roundedBottom ? "mb-0 lg:-mb-10" : ""
            )}
            {...sbEditable(blok)}
          >
            <div className="lg:hidden h-auto overflow-hidden flex justify-center mx-[-20%]">
              <div className={mergeClasses("rounded-bl-full rounded-br-full object-cover max-w-[896px] h-[896px] mt-[-400px] w-full flex justify-center overflow-hidden bg-white")}>
                <div className="mt-[400px] mx-[-100%]">
                  <img
                    className="h-[496px] w-auto mx-auto"
                    src={blok.image?.filename}
                    alt={""}
                  />
                </div>
              </div>
            </div>
            <div
              className={mergeClasses(
                "lg:grid lg:grid-cols-4",
                blok.roundedBottom ? "" : "lg:h-[800px]",
                blok.roundedTop ? "" : "lg:mt-[-96px]"
              )}
            >
              <div className="flex justify-start px-6 lg:px-0 pt-12 lg:pt-0 lg:justify-center items-center lg:col-span-2 z-10">
                <div className="w-[455px]">
                  <div>
                    {PropertyExists(blok.title) && (
                      <h2
                        className={mergeClasses(
                          "text-3xl pb-7 font-montserrat",
                          isTextLight ? "text-white" : "text-royal-blue-900"
                        )}
                      >
                        {blok.title}
                      </h2>
                    )}

                    {ResolveRichText.render(blok?.bodyText).length > 0 && (
                      <span
                        className={mergeClasses(
                          "text-lg articleCopy",
                          isTextLight
                            ? "text-royal-blue-200"
                            : "text-royal-blue-800"
                        )}
                        dangerouslySetInnerHTML={{
                          __html: ResolveRichText.render(blok?.bodyText),
                        }}
                      />
                    )}

                    {PropertyExists(blok.linkText) && (
                      (<Link
                        href={absoluteUrl(blok.link?.story?.url ?? "#")}
                        className="text-lg pt-6 font-bold underline text-peachy-orange-900 bg-invisible">

                        <br />
                        {blok.linkText}

                      </Link>)
                    )}
                  </div>
                </div>
              </div>
              <div
                className={mergeClasses(
                  "hidden lg:block lg:col-span-2",
                  isImageLeft ? "order-first" : "order-last"
                )}
              >
                <div className="h-[896px] w-[896px]">
                  <img
                    alt={""}
                    className={mergeClasses(
                      "object-cover w-[896px] h-[896px]",
                      isImageLeft ? "ml-[-340px] rounded-r-full" : "mr-[-340px] rounded-l-full"
                    )}
                    src={blok.image?.filename}
                    width="896"
                    height="896"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CircleImageWithText;
