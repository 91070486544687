import Image from "next/legacy/image";
import Link from "next/link";
import { sbEditable } from "@storyblok/storyblok-editable";
import { HeroIcon } from "../lib/HeroIcon";

const SixPanelSectionCTA = ({ blok }) => {
  return (
    <div {...sbEditable(blok)} className="border-l border-r border-t border-grey-600">
      <div className="flex flex-col rounded-sm overflow-hidden h-fit min-h-[400px]">
        <div className="flex-1 bg-cto_secondary_light_warm_grey px-10 py-4 flex flex-col justify-between break-words">
          <div className="py-6">
            <p className="font-montserrat font-bold text-3xl text-cto_primary_orange">
              {blok?.titleTop} 
            </p>
            <p className="font-montserrat font-bold text-3xl text-cto_secondary_navy_blue">
              {blok?.titleBottom}
            </p>
          </div>
          <div className="text-gray-500 break-all text-2xl lg:text-xl xl:text-2xl">
            <div className="py-6">
              <a href={"tel:" + blok?.phone}>
                <p>{blok?.phone}</p>
              </a>
            </div>
            <div className="py-4">
              <a href={"mailto:" + blok?.email}>
                <p>{blok?.email.substring(0, blok.email.indexOf('@'))}</p>
                <p>{blok?.email.substring(blok.email.indexOf('@'))}</p>
              </a>
            </div>
          </div>
          {blok?.url?.story?.full_slug.length > 0 && (
            <div className=" flex items-center py-4">
              <Link href={blok?.url?.story?.full_slug}>

                <span className="underline text-cto_primary_orange font-dm-sans text-lg font-bold bg-invisible">
                  {blok?.urlText}
                </span>

              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SixPanelSectionCTA;
