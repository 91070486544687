import ArticleCard from "../../blog-sections/ArticleCard";

const ListAllCareers = ({ blok, shared }) => {
  const careers = shared.careers;

  return (
    <div className="flex justify-center">
      <div className="max-w-7xl px-4 grid grid-cols-1 gap-8 md:grid-cols-3 lg:grid-cols-3 py-12">
        {careers.map((career, i) => {
          return (
            <div key={i}>
              <ArticleCard blok={career} useDefaultImage={true} />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default ListAllCareers;
