import React from "react";
import { sbEditable } from "@storyblok/storyblok-editable";
import DynamicComponent from "./DynamicComponent";
import { mergeClasses } from "../lib/_helper";

const SplitContainer = ({ blok }) => {
  return (
    <div {...sbEditable(blok)} className={mergeClasses(
      blok.backgroundColour ? "bg-" + blok.backgroundColour : "bg-transparent"
    )}>
      <div className="max-w-7xl mx-auto grid grid-cols-1 lg:grid-cols-2">
        <div className="col-span-1 flex items-center">
          <div className="w-full">
            {blok.left.map((card, index) => (
              <DynamicComponent blok={card} key={index} />
            ))}
          </div>
        </div>
        <div className="col-span-1 flex items-center">
          <div className="w-full">
            {blok.right.map((card, index) => (
              <DynamicComponent blok={card} key={index} />
            ))}
          </div>
        </div>
      </div>
    </div>);
};

export default SplitContainer;
