import { sbEditable } from "@storyblok/storyblok-editable";
import { mergeClasses } from "../lib/_helper";
import HomeHeroCard from "./HomeHeroCard";

const HomeHeroCardContainer = ({ blok }) => {
  const cards = blok?.cards ?? [];

  return (
    <div className="relative bg-transparent pt-8 pb-8 px-4 sm:px-6 lg:pt-12 lg:pb-12 lg:px-8" {...sbEditable(blok)}>
      {!blok.hidePartialBackground && <div className="absolute inset-0">
        <div className=" bg-cto_secondary_navy_blue h-4/5 sm:h-3/5" />
      </div>}
      <div className="relative max-w-7xl mx-auto">
        <div className={mergeClasses(
          "max-w-lg mx-auto grid gap-10 lg:max-w-none",
          (cards.length == 1) ? "lg:grid-cols-1" : "",
          (cards.length == 2) ? "lg:grid-cols-2" : "",
          (cards.length == 3) ? "lg:grid-cols-3" : ""
        )}>
          {cards.map((card) => (
            <HomeHeroCard blok={card} key={card.title} numCards={cards.length} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default HomeHeroCardContainer;