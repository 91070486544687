import DynamicComponent from './DynamicComponent';
import { sbEditable } from "@storyblok/storyblok-editable";
import { mergeClasses } from '../lib/_helper';
import '@animxyz/core'
import { XyzTransitionGroup } from "@animxyz/react";

// To allow this blok to accept more than two panels, you would just need to make `lg:grid-cols-2` dynamic to `lg:grid-cols-X` where X is the number of columns...
// and make the LEFT PANEL / RIGHT PANEL part dynamic

const HorizontalContainer = ({ blok, shared }) => {
  return (
    <div className={mergeClasses(blok.backgroundColour ? "bg-" + blok.backgroundColour : "bg-gray-400")} {...sbEditable(blok)}>
      <div className="py-24">
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24 relative">
          {/* LEFT PANEL */}
          <div className={"mt-12 sm:mt-16 lg:mt-0 w-full align-middle"}>
            <div className={mergeClasses("pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full")}>
              <XyzTransitionGroup appearVisible={blok.animateLeftPanels} xyz="fade left-4 duration-8 ease">
                {blok.leftPanels.map((panel, i) => {
                  return (
                    <div key={i}>
                      <DynamicComponent blok={panel} shared={shared} />
                    </div>
                  )
                })}
              </XyzTransitionGroup>
            </div>
          </div>

          {/* RIGHT PANEL */}
          <div className={"mt-12 sm:mt-16 lg:mt-0 w-full align-middle"}>
            <div className={mergeClasses("pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full")}>
              <XyzTransitionGroup appearVisible={blok.animateRightPanels} xyz="fade right-4 duration-8 ease">
                {blok.rightPanels.map((panel, i) => {
                  return (
                    <div key={i}>
                      <DynamicComponent blok={panel} shared={shared} />
                    </div>
                  )
                })}
              </XyzTransitionGroup>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HorizontalContainer;